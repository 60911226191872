import React, { useEffect, useState } from "react"
import * as styles from "components/tools/modal.module.css"

const Alert = props => {
  console.log(props.children)
  return (
    <div
      className={
        props.open ? `${styles.open} ${styles.modal}` : `${styles.modal}`
      }
    >
      {props.open ? <>{props.children}</> : null}
    </div>
  )
}

export default Alert
