import dayjs from "dayjs"
export default class Shop {
  constructor(data) {
    this._data_shopList = data.ShopItem
    this.magazine_intro = data.magazine_intro
    this.magazine_title = data.magazine_title
    this.thumb_magazine_uuid = data.thumb_magazine_uuid
    //
    this.status = data.status
    this.shopList = new Map([])

    this.shopList_init()
  }

  // data정렬
  shopList_init() {
    if (this.status) {
      this._data_shopList.forEach(shop => {
        const goods_no = shop.goods_no

        const price = getPrice(shop)
        const title = shop.Goods.title
        const month = shop.Goods.month
        const isMagazine = shop.Goods.magazine
        const isReg = month === 0
        //test
        const plan = getPlan(month)
        const period = getPeriod(month)
        const begin = dayjs().unix()
        const end = dayjs().add(month, "month").locale("ko").unix()
        const couponSepar = getCouponSepal(
          shop.Goods.month,
          shop.Goods.magazine
        )

        this.shopList.set(goods_no, {
          goods_no,
          price,
          title,
          month,
          isMagazine,
          isReg,
          plan,
          period,
          begin,
          end,
          couponSepar,
        })
      })
    }
  }

  toJson() {
    const jsonList = JSON.stringify(Array.from(this.shopList.entries()))
    this.shopList = jsonList
    return this
  }

  parseJson() {
    const parseList = new Map(JSON.parse(this.shopList))
    this.shopList = parseList
    return this
  }
}

const getPrice = shop => {
  const basePrice = shop.Goods.basePrice
  const goodsPrice = shop.goods_price

  //차후 쿠폰할인 적용
  if (goodsPrice === undefined || basePrice === undefined) {
    return null
  } else if (goodsPrice !== 0) {
    return goodsPrice
  } else if (basePrice !== 0) {
    return basePrice
  } else {
    return 0
  }
}

const getPlan = month => {
  if (month) {
    return `피치서가 ${month}개월 구독`
  } else if (month === 0) {
    return `피치서가 정기구독`
  } else {
    return ""
  }
}

const getPeriod = month => {
  if (month === 0) {
    return "정기구독"
  } else if (!month) {
    return ""
  } else {
    const begin = dayjs().locale("ko").format("YYYY년 M월 D일")
    const end = dayjs()
      .add(month, "month")
      .locale("ko")
      .format("YYYY년 M월 D일")

    return `${begin}\n${end}`
  }
}
import { couponState } from "utils/data"
// 정기구독(1) , 온라인 6개월(2) , 온라인 매거진 6개월(3), 온라인 12개월(4) , 온라인 매거진 12개월(5)
const getCouponSepal = (month, isMagazine) => {
  if (month === 0) {
    return couponState.REG
  } else if (month === 6) {
    return isMagazine ? couponState.MAGAZINE6 : couponState.ONLINE6
  } else if (month === 12) {
    return isMagazine ? couponState.MAGAZINE12 : couponState.ONLINE12
  }
}
