import React, { useEffect } from "react"
import Modal from "components/tools/modal.js"
import modalContext from "./modalContext"
import alertContext from "./alertContext"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { setContextError } from "store/modules/errorReducer.js"
import BackSpace from "components/backSpace.js"
import Alert from "components/tools/alert.js"
import { useModal, useAlert } from "hooks"
import { Location } from "@reach/router"
const App = props => {
  const [modalOpen, modalMain, setModal] = useModal()
  const [alertOpen, alertMain, setAlert] = useAlert()

  const dispatch = useDispatch()

  const errorDesc = useSelector(
    ({ errorReducer: { errorDesc } }) => errorDesc,
    shallowEqual
  )

  const { setAlertOpen, setAlertMain } = setAlert

  globalThis.alert = (msg, color) => {
    setAlertOpen(true)
    setAlertMain(prev => {
      return (
        <div style={outStyle} onClick={() => setAlertOpen(false)}>
          <div className={"modalText"} style={inStyle}>
            {msg}
            <div
              style={{
                background: color ? `${color}` : "",
              }}
            >
              확인
            </div>
          </div>
        </div>
      )
    })
  }
  globalThis.confirm = async msg => {
    const subFlag = msg === "구독자 정보가 저장되어 있습니다."
    let flag = null
    setAlertOpen(true)
    setAlertMain(
      <div style={outStyle} onClick={() => setAlertOpen(false)}>
        <div className={"modalText"} style={inStyle}>
          {msg}
          <p style={{ display: "flex" }}>
            <div
              style={{ marginRight: "17px" }}
              onClick={() => {
                flag = false
                subFlag && navigate("/member/setting/SettingAccount/")
              }}
            >
              {subFlag ? "정보 수정하기" : "확인"}
            </div>
            <div
              style={{ marginLeft: "17px" }}
              onClick={() => {
                flag = true
                subFlag && navigate("/subscription/payments")
              }}
            >
              {subFlag ? "구독 진행하기" : "취소"}
            </div>
          </p>
        </div>
      </div>
    )

    while (flag === null) {
      await new Promise(resolve => setTimeout(resolve, 500))
    }
    return flag
  }

  useEffect(() => {
    errorHandler(errorDesc, setModal)
    return () => {
      dispatch(setContextError(null))
    }
  }, [errorDesc])

  // useEffect(() => {
  //   !getIsApp() && window.addEventListener("beforeunload", logOut)
  //   return () => {
  //     !getIsApp() && window.removeEventListener("beforeunload", logOut)
  //   }
  // }, [])

  return (
    <modalContext.Provider value={setModal}>
      <alertContext.Provider value={setAlert}>
        {props.children}
        <Location>
          {locationProps => <BackSpace {...locationProps} {...props} />}
        </Location>
        <Modal open={modalOpen} setModal={setModal}>
          {modalMain}
        </Modal>
        <Alert open={alertOpen} setAlert={setAlert}>
          {alertMain}
        </Alert>
      </alertContext.Provider>
    </modalContext.Provider>
  )
}
export default App

import { errDesc } from "utils/data"
import { navigate } from "gatsby-link"

const errorHandler = async (errorDesc, setModal) => {
  const path = globalThis.location.pathname

  if (!errorDesc) {
    return
  } else if (
    (path.includes("profile") ||
      path.includes("modal") ||
      path === "/login" ||
      path === "/") &&
    errorDesc === "profile required"
  ) {
    return
  } else if (errorDesc === "invalid finger") {
    return
  } else if (errorDesc === "login required") {
    navigate("/login")
    alert("다시 로그인 해주세요.")
    return
  }

  const { setmodalOpen, setModalMain } = setModal
  const { link, mainMsg, btnMsg } = errDesc.get(errorDesc)

  const onEnd = () => {
    setmodalOpen(false)
    if (link) navigate(link)
  }

  console.log(mainMsg)
  console.log(setmodalOpen)
  setmodalOpen(true)
  setModalMain(
    <div style={outStyle} onClick={() => setmodalOpen(false)}>
      <div className={"modalText"} style={inStyle}>
        {mainMsg}
        <div
          onClick={onEnd}
          style={btnMsg === "구독하러 가기" && { fontWeight: "400" }}
        >
          {btnMsg}
        </div>
      </div>
    </div>
  )
}

const outStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "0px",
  backgroundColor: "rgba(0, 0, 0, 0)",
}

const inStyle = {
  backgroundColor: "white",
  borderRadius: "7px",
  position: "absolute",
  top: "0",
  bottom: "0",
  right: "0",
  left: "0",
  margin: "auto",
}
