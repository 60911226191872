import { createAction, handleActions } from "redux-actions"
import produce from "immer"

const GETPROFILE = "store/module/GETPROFILE"
export const getProfile = createAction(GETPROFILE)

const getProfileAsync = information => async dispatch => {
  await dispatch(getProfile(information))
}

export { getProfileAsync }

const initialState = {
  Profile: [],
}

const ProfileReducer = handleActions(
  {
    [GETPROFILE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.Profile = [payload]
      })
    },
  },
  initialState
)

export default ProfileReducer
