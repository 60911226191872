import { createAction, handleActions } from "redux-actions"
import produce from "immer"
// import json from "contents/classLesson.json"
const SETSORTOPTION = "store/module/SETSORTOPTION"
const SETOPTION = "store/module/SETOPTION"
export const setSortOption = createAction(SETSORTOPTION)
export const setOption = createAction(SETOPTION)
export const sortOptionDispatch = param => async dispatch => {
  await dispatch(setSortOption(param))
}

export const optionDispatch = param => async dispatch => {
  await dispatch(setOption(param))
}

const initialState = {
  searchOption: {
    sort: "최근등록순",
    target: "전체",
    subject: "전체",
    kind: "전체",
  },
}

const post2Reducer = handleActions(
  {
    [SETSORTOPTION]: (state, { payload }) => {
      return produce(state, draft => {
        draft.searchOption["sort"] = payload
      })
    },
    [SETOPTION]: (state, { payload: { key, value } }) => {
      return produce(state, draft => {
        draft.searchOption[key] = value
      })
    },
  },
  initialState
)

export default post2Reducer
