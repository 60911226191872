export default class CouponVO {
  constructor(dvo) {
    this.number = dvo.seq_no

    this.day = dvo.Coupon.benefit_day
    this.end = dvo.Coupon.end_valid
    this.title = dvo.Coupon.title
    this.discount = dvo.Coupon.discount_amount
    this.discountRate = dvo.Coupon.discount_rate
    this.isTrial = dvo.Coupon.trial
    this.couponSepar = dvo.Coupon.target_goods
  }
}

// 정기구독(1) , 온라인 6개월(2) , 온라인 매거진 6개월(3), 온라인 12개월(4) , 온라인 매거진 12개월(5)
