import React from "react"
import * as styles from "./lesson.module.css"
import { getThumb } from "utils"
import Like from "components/tools/Like"
import { permission } from "utils"
import { onFavor, offFavor } from "images"
import { lessonMenu } from "utils/data"
import NewBadge from "components/tools/newBadge"
const Lesson = ({ post }) => {
  const keys = ["course", "curriculum", "kind"]

  const getAddPost = key => {
    const addPost = {}

    Array.from(lessonMenu.get(key).contents.keys()).forEach((elem, idx) => {
      addPost[elem] = post[key] & (1 << idx) ? true : false
    })
    return addPost
  }

  const getkey = key => {
    const addPost = getAddPost(key)

    return Array.from(lessonMenu.get(key).contents).reduce(
      (initial, [key, value]) =>
        addPost[key] ? [...initial, value] : [...initial],
      []
    )
  }
  const nanum_course = getkey(keys[0]).join()
  // const nanum_curriculum = getkey(keys[1]).join()
  const nanum_curriculum = (() => {
    const nc = getkey(keys[1])
    const maxLength = 4
    return nc.length >= 4 ? `${nc.splice(0, maxLength).join()}...` : nc.join()
  })()
  const nanum_kind = getkey(keys[2]).map((v, i) => <div key={i}>{v}</div>)

  return (
    <div key={post.nanum_no} className={styles.con}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "250px",
          }}
        >
          {/* <img
            src={post?.Like ? onFavor : offFavor}
            onClick={e => {
              onLike(e, post.nanum_no, "nanum")
            }}
            name="like"
          /> */}
          <Like
            on={onFavor}
            off={offFavor}
            keyword={"nanum"}
            isLike={post?.Like}
            style={{ img: { width: "40px", height: "40px" } }}
            postNumber={post.nanum_no}
          />
          <NewBadge registerTime={post.publishTime} />
        </div>

        <img
          src={getThumb(post.Story?.thumb_title_uuid)}
          onClick={() =>
            permission(`/class/lesson/detail/?number=${post.nanum_no}`)
          }
          style={{
            width: "250px",
            height: "250px",
          }}
        />
      </div>

      <div>
        <div>{post.title}</div>
        <div>{`${nanum_course}을 위한 수업`}</div>
        <div
          style={{
            minHeight: "28px",
          }}
        >
          {nanum_kind}
        </div>
        <hr />
        <div>
          <div>{nanum_curriculum}</div>
          <div>{post?.Profile?.name}</div>
        </div>
      </div>
    </div>
  )
}

export default Lesson
