import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"

const GETTODAYSTORY = "store/module/frontReducer/GETTODAYSTORY"
const GETTODAYCARD = "store/module/frontReducer/GETTODAYCARD"
const GETPICKSTORY = "store/module/frontReducer/GETPICKSTORY"
const GETYOURSTORY = "store/module/frontReducer/GETYOURSTORY"
const GETEDITORCURATION = "store/module/frontReducer/GETEDITORCURATION"
const GETEDUCATIONCURATION = "store/module/frontReducer/GETEDUCATIONCURATION"
const GETVIDEO = "store/module/frontReducer/GETVIDEO"
const GETSUGGESTSTORY = "store/module/frontReducer/GETSUGGESTSTORY"

export const getTodayStory = createAction(GETTODAYSTORY)
export const getTodayCard = createAction(GETTODAYCARD)
export const getPickStory = createAction(GETPICKSTORY)
export const getYourStory = createAction(GETYOURSTORY)
export const getEditorCuration = createAction(GETEDITORCURATION)
export const getEducationCuration = createAction(GETEDUCATIONCURATION)
export const getSuggestStory = createAction(GETSUGGESTSTORY)
export const getVideo = createAction(GETVIDEO)

export const getTodayStoryAsync = () => async dispatch => {
  const result = await api.getFront("todayStory")
  if (!result) return

  dispatch(getTodayStory(result))
}

export const getTodayCardAsync = () => async dispatch => {
  const result = await api.getFront("todayCard")

  if (!result) return

  dispatch(getTodayCard(result))
}

export const getUserStoryAsync = memberType => async dispatch => {
  const result = await api.getFront(memberType)

  console.log(result)
  if (!result) return

  dispatch(getYourStory(result))
}

export const getPickStoryAsync = () => async dispatch => {
  const result = await api.getFront("likeStory")
  console.log(result)

  if (!result) return

  dispatch(getPickStory(result))
}

export const getEdiCurationAsync = () => async dispatch => {
  const result = await api.getFront("editorCuration")
  console.log(result)

  if (!result) return

  dispatch(getEditorCuration(result))
}

export const getEduCurationAsync = () => async dispatch => {
  const result = await api.getFront("eduCuration")
  console.log(result)

  if (!result) return

  dispatch(getEducationCuration(result))
}

export const getVideoStoryAsync = () => async dispatch => {
  const result = await api.getFront("videoStory")
  console.log(result)

  if (!result) return

  dispatch(getVideo(result))
}

export const getSuggestAsync = () => async dispatch => {
  const result = await api.getFront("suggest")
  console.log(result)

  if (!result) return

  dispatch(getSuggestStory(result))
}

const initialState = {
  todayStory: [],
  todayCard: [],
  likeStory: [],
  forUserStory: [],
  editorCuration: [],
  eduCuration: [],
  videoStory: [],
  suggest: [],
}

const frontReducer = handleActions(
  {
    [GETTODAYSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.todayStory = payload.data.stories
      })
    },
    [GETTODAYCARD]: (state, { payload }) => {
      return produce(state, draft => {
        draft.todayCard = payload.data.illusts
      })
    },
    [GETYOURSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.forUserStory = payload.data.stories
      })
    },
    [GETPICKSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.likeStory = payload.data.stories
      })
    },
    [GETEDITORCURATION]: (state, { payload }) => {
      return produce(state, draft => {
        draft.editorCuration = payload.data.exhibit
      })
    },
    [GETEDUCATIONCURATION]: (state, { payload }) => {
      return produce(state, draft => {
        draft.eduCuration = payload.data.exhibit
      })
    },
    [GETSUGGESTSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.suggest = payload.data.edu_nanums
      })
    },
    [GETVIDEO]: (state, { payload }) => {
      return produce(state, draft => {
        draft.videoStory = payload.data.videos
      })
    },
  },
  initialState
)

export default frontReducer
