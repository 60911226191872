import { combineReducers } from "redux"
import ProfileReducer from "store/modules/profileReducer.js"
import post2Reducer from "store/modules/post2Reducer.js"
import postReducer from "store/modules/postReducer.js"
import classRoomReducer from "store/modules/classRoomReducer.js"
import exhibitionReducer from "store/modules/exhibitionReducer.js"
import categoryReducer from "store/modules/categoryReducer.js"
import yourStoryReducer from "store/modules/yourStoryReducer.js"
import interestReducer from "store/modules/interestReducer.js"
import bannerReducer from "store/modules/bannerReducer.js"
import frontReducer from "store/modules/frontReducer.js"
import searchReducer from "store/modules/searchReducer.js"
import myShelfReducer from "store/modules/myShelfReducer.js"
import errorReducer from "store/modules/errorReducer.js"
import footerReducer from "store/modules/footerReducer.js"
import payInfoReducer from "store/modules/payInfoReducer.js"
import alarmReducer from "store/modules/alarmReducer.js"
import hiddenReducer from "store/modules/hiddenReducer.js"
const rootReducer = combineReducers({
  ProfileReducer,
  post2Reducer,
  postReducer,
  classRoomReducer,
  exhibitionReducer, //
  categoryReducer,
  yourStoryReducer,
  interestReducer,
  bannerReducer,
  frontReducer, //
  searchReducer,
  myShelfReducer,
  errorReducer,
  footerReducer,
  payInfoReducer,
  alarmReducer,
  hiddenReducer,
})
export default rootReducer
