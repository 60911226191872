export const API_URI = process.env.API

const address = Object.freeze({
  signUp: "v2/account/signup/local", //회원가입
  idOverlap: "v2/account/check/id?account_id=", // 아이디중복
  //getMobileConfirm: "v2/phone/reset/code", // 인증번호 받기
  getMobileConfirm: "v2/phone/issue/code",
  mobileConfirm: "v2/phone/confirm/code", // 인증하기
  registerProfile: "v2/profile", //프로필 등록
  login: "v2/account/signin/local", // 로그인,
  idmobile: "v2/account/help/id/phone", // 아이디 찾기 - 모바일
  idemail: "v2/account/help/id/email", // 아이디 찾기 - 이메일
  pwdemail: "v2/account/help/passwd/email", // 비밀번호 찾기(리셋) - 이메일
  pwdmobileCheck: "v2/account/help/passwd/phone/check", // 비밀번호 찾기(리셋) - 모바일
  pwdmobileReset: "v2/account/help/passwd/phone/reset", // 비밀번호 찾기(리셋) - 모바일
  coupon: "v2/coupon/all", // 쿠폰
  reviseFingerprint: "v2/account/help/finger/phone", // 리셋 - 내보내기(핑거프린트 갱신)
  readProfile: "v2/profile/detail", //프로필 읽기
  reviseIcon: "v2/profile/icon", //프로필 아이콘 수정
  reviseName: "v2/profile/name", //프로필 이름 수정
  revisePhone: "v2/profile/phone", //프로필 핸드폰 수정
  reviseEmail: "v2/profile/email", //프로필 이메일 수정
  reviseSubject: "v2/profile/topics", //프로필 관심주제 수정
  cardStoryList: "v2/illust/board?", // 카드 스토리 리스트 불러오기
  cardStoryDetail: "v2/illust/detail?illust_number=", // 카드 상세 정보 불러오기
  detailedClassinfo: "v2/story/detail?story_number=", //스토리 상세 가져오기
  detailedRoominfo: "v2/edutalk/detail?talk_number=", //교실이야기 상세 가져오기
  like: {
    story: "v2/story/like", // 스토리 찜하기
    illust: "v2/illust/like", // 그림 찜하기
    video: "v2/video/like", // 비디오 찜하기
    exhibit: "v2/exhibit/like", // 기획전 찜하기
    nanum: "v2/edunanum/like", // 수업나눔 찜하기
    talk: "v2/edutalk/like", // 교실이야기 찜하기
    book: "v2/bookstory/like", // 월간피치서가 찜하기
  },
  associated: {
    story:
      "v2/story/association/story?page_number=1&take_number=0&order_number=1&story_number=", // 스토리의 연관 스토리
    story_nanum: "v2/story/association/edunanum?story_number=", //스토리넘버로 수업나눔 리스트 조회
    illust:
      "v2/illust/association/story?page_number=1&take_number=0&order_number=1&illust_number=", // 그림이야기의 연관스토리
    class:
      "v2/story/association/nanum?page_number=1&take_number=0&order_number=1&story_number=", // 교실이야기의 연관 스토리
    exhibit:
      "v2/exhibit/association/story?page_number=1&take_number=0&order_number=1&exhibit_number=", // 기획전의 연관 스토리
    room: "v2/edutalk/association/story?page_number=1&take_number=0&talk_number=", // 교실이야기의 연관 스토리
    month:
      "v2/bookstory/association/story?page_number=1&take_number=0&order_number=1",
    nanum:
      "v2/edunanum/association/nanum?page_number=1&take_number=0&order_number=1&nanum_number=", //수업나눔 연관 리스트
  },
  classRoomList: "v2/edutalk/board?", // 교실 이야기 리스트 불러오기
  exhibitionList: "v2/exhibit/board?", //기획전 리스트 불러오기
  exhibitionDetail: "v2/exhibit/detail?exhibit_number=", //기획전 상세가져오기
  videoList: "v2/video/board?", // 비디오 리스트
  videoDetail: "v2/video/detail?video_number=", // 비디오 상세
  storyList: "v2/story/board?", // 스토리 리스트
  yourStoryList: "v2/free/story/board?", // 당신을 위한 스토리 리스트
  monthList: "v2/bookstory/board?", // 월간 피치서가 리스트
  monthDetail: "v2/bookstory/detail?book_number=", // 월간 피치서가 디테일
  noticeList: "v2/news/board?", //소식 리스트
  noticeDetail: "v2/news/detail?news_number=", // 소식 상세
  bannerDetail: "v2/banner?banner_position=", // 배너정보
  bannerHit: "v2/banner/hit", //배너 조회수 증가
  termDetail: "v2/term?term_kinds=", // 약관상세 정보
  faqList: `v2/faq/board?page_number=1&take_number=0&order_number=1`, // faq리스트
  faqDetail: `v2/faq/detail?faq_number=`, // faq 답변
  contact: "v2/question", // 1대1문의하기
  contactList: "v2/question/board?", // 1대1문의 목록
  contactDetail: "v2/question/detail?question_number=", // 1대1문의 상세
  answerDetail: "v2/question/answer/detail?answer_number=",
  front: {
    todayStory: "v2/today/story", //오늘의 스토리
    todayCard: "v2/today/illust", //오늘의 단어
    likeStory: "v2/today/dibbs/story", //김피치님이 찜한 스토리
    forUserStory: "v2/today/topic/story", //김피치님을 위한 스토리
    forUnknownUserStory: "v2/today/free/story", //당신을 위한 스토리
    editorCuration: "v2/today/exhibit/editor", //에디터팀 큐레이션
    eduCuration: "v2/today/exhibit/education", //교육팀 큐레이션
    videoStory: "v2/today/video", //영상 스토리
    suggest: "v2/today/edunanum",
  },
  lessonList: "v2/edunanum/board?",
  search: "v2/search/",
  lessonWrite: "v2/edunanum", // 수업나눔 추가
  lessonDetail: "/v2/edunanum/detail?nanum_number=", // 수업나눔 상세
  lessonRemove: "v2/edunanum", // 수업나눔 삭제
  lessonMod: "v2/edunanum/edit",
  comment: "v2/edunanum/comments", //  댓글입력
  commentList: "v2/edunanum/comments?nanum_number=", // 댓글리스트
  commentDeclare: "v2/edunanum/comments/report", //댓글신고
  intro: "v2/intro", // 소개 정보
  setting: {
    addSubscriber: "v2/subscribe/person",
    account: "v2/account/detail",
    subscriber: "v2/subscribe/detail",
    shop: "v2/subscribe/shop/detail",
    reviseAccountName: "v2/account/name",
    reviseAccountPassword: "v2/account/passwd",
    reviseAccountPhone: "v2/account/phone",
    reviseAccountEmail: "v2/account/email",
    reviseSubscribername: "v2/subscribe/name",
    reviseSubscriberaddress: "v2/subscribe/address",
    reviseSubscriberphone: "v2/subscribe/phone",
  },
  groupJoin: "v2/organization", // v2/faq/organization
  scb: "v2/subscribe/person", // add
  scbDetail: "v2/subscribe/detail",
  shopDetail: "v2/subscribe/shop/detail",
  footer: "v2/footer",
  pay: {
    begin: "v2/subscribe/billing/begin",
    end: "v2/subscribe/billing/end",
    cancel: "v2/subscribe/billing/cancel",
    detail: "v2/billing/iamport/detail?billing_number=",
    list: "v2/billing/iamport/all",
    trial: "v2/subscribe/billing/trial",
  },
  scbCancel: "v2/subscribe/billing/close",
  scbHistory: "v2/billing/history",
  billingDetail: "v2/billing/detail?billing_number=",
  billingMethod: "v2/billing/method?billing_number=",
  scbCloseCancel: "v2/subscribe/billing/close/cancel",
  //나의서재
  myShelf: {
    //나의서가
    recentStory: "v2/my/recent/story", // 최근 본 스토리
    recentLesson: "v2/my/recent/edunanum", // 최근 본 수업나눔
    writedClass: "v2/my/made/edunanum/board?",
    likePost: "v2/my/dibbs/board?",
  },
  addStorage: "v2/storage",
  alarmList: "v2/alarm",
  alarmCheck: "v2/alarm/check",
  hiddenProject: "v2/hidden/detail?hidden_number=", //프로젝트 상세
  hiddenProjectList: "v2/hidden/board?",
  hiddenStory: "v2/hidden/story/detail?story_number=", //히든스토리
  hiddenStoryList: "v2/hidden/story/board?", //히든스토리 리스트
  getPopup: "v2/popup",
  getLanding: "v2/landing",
  getLessonTitle: "v2/alarm/edunanum?nanum_numbers=",
  insertLog: "v2/client/log",
  eduAttach: "v2/edunanum/attach",
  addStorage: "v2/storage",
})

export default address
//상점상세에서 매거진 정보 요청
//상품금액과 할인금액,결제금액
