import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const GETCLASSROOMLIST = "store/module/classRoomReducer/GETCLASSROOMLIST"
export const getClassRoomList = createAction(GETCLASSROOMLIST)

export const getClassRoomListAsync = (page, take, order) => async dispatch => {
  const result = await api.getClassRoomList(page, take, order)
  if (!result?.pageList?.length) {
    dispatch(
      getClassRoomList({
        pageList: [],
        total: 0,
      })
    )
  } else {
    dispatch(getClassRoomList(result))
  }
}

const initialState = {
  classRoomList: [],
  total: 0,
}

const classRoomReducer = handleActions(
  {
    [GETCLASSROOMLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.classRoomList = payload.pageList
        draft.total = payload.total
      })
    },
  },
  initialState
)

export default classRoomReducer
