import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const GETINTERESTLIST = "store/module/interestReducer/GETINTERESTLIST"
export const getInterestList = createAction(GETINTERESTLIST)

export const getInterestListAsync =
  (page, take, order, topic) => async dispatch => {
    const result = await api.getInterestList(page, take, order, topic)

    if (!result?.pageList.length) {
      dispatch(
        getInterestList({
          pageList: [],
          total: 0,
        })
      )
    } else {
      dispatch(getInterestList(result))
    }
  }

const initialState = {
  interestList: [],
  total: 0,
}

const interestReducer = handleActions(
  {
    [GETINTERESTLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.interestList = payload.pageList
        draft.total = payload.total
      })
    },
  },
  initialState
)

export default interestReducer
