import { getDateYMD, getJson } from "utils"

export default class AlarmVO {
  constructor(dvo) {
    this.number = dvo.alarm_no
    this.action_no = dvo.action_no
    this.isNew = dvo.isNew
    this.body = getBody(dvo.body, this.action_no)
    this.time = getDateYMD(dvo.registerTime, "")
    this.isClick = false
  }

  async onClick() {
    const result = await api.AlarmCheck({
      body: {
        alarm_number: this.number,
      },
    })

    console.log(result)
    this.isClick = result
  }
}

const action = {
  A: 1, // 회원가입
  B: 2, // 첫 결제
  C: 3, // 정기구독 갱신
  D: 4, // 6 개월 플랜
  E: 5, // 12 개월 플랜
  F: 6, // 1:1 문의 답변 완료시
  G: 7, // 댓글 신고 처리 완료시 해당 사용자에게 발송
  H: 8, // 사용자가 작성한 수업나눔에 댓글이 달리시 알림
}

const getBody = (body, number) => {
  console.log(body)
  let text
  try {
    text = JSON.parse(body)
    console.log(423, text)
  } catch (err) {
    text = ""
  }

  // switch문으로 알람에 따란 body 재구성 필요

  let result

  switch (number) {
    case action.A:
      result = {
        title: `회원가입`,
        text: `${text.name}님, 피치서가 <span>#회원가입</span>을 환영합니다.`,
        // text: `${text.name}님, 피치서가 #회원가입을 환영합니다.`,
        // id: "cf98741" name: "김강철" time: 1646208162
      }
      break
    case action.B:
      result = {
        title: `첫결제`,
        text: `님, 피치서가를 <span>#구독</span>해주셔서 감사합니다.`,
        link: `/member/setting/SettingAccount/`,
        // amount: 3000 goods_no: 1005
      }
      break
    case action.C:
      result = {
        title: ` 정기구독 갱신`,
        text: `부터 <span>#정기구독</span>이 갱신되었습니다..`,
        link: `/member/setting/SettingAccount/`,
      }

      break
    case action.D:
      result = {
        title: `6개월 플랜 구독`,
        text: `부터 <span>#6개월 플랜</span> 구독 회원입니다.`,
        link: `/member/setting/SettingAccount/`,
        // amount: 3000   goods_no: 1003
      }

      break
    case action.E:
      result = {
        title: `12개월 플랜 구독`,
        text: `부터 <span>#12개월 플랜</span> 구독 회원입니다.`,
        link: `/member/setting/SettingAccount/`,
        //  amount: 3000   goods_no: 1005
      }

      break
    case action.F:
      result = {
        title: `고객센터`,
        text: `님, <span>#1:1 문의</span> 답변을 확인하세요.`,
        link: `/cs/contact/list`,
        //{"question":{"no":1010,"title":"12321321"},"answer":{"no":1009,"name":"김정희"}}
      }
      break
    case action.G:
      result = {
        title: `고객센터`,
        text: test(text),
        link: `/class/lesson/detail/?number=${text.nanum_no}`,
        number: text.nanum_no,
      }

      break

    case action.H:
      result = {
        title: `수업나눔 댓글`,
        text: `에 댓글이 등록되었습니다. `,
        link: `/class/lesson/detail/?number=${text.nanum_no}`,
        number: text.nanum_no,
        //  comment_no: 1042  nanum_no: 1095
      }
      break
    default:
      result = `알람이 없어요.`
      console.log(result)
      break
  }

  return result
}

const test = text => {
  let id = ""
  const profileName = getJson("profile")?.name ?? ""

  if (text.report_status === 2) {
    if (text.type === "reportee") {
      id += "에 등록한 댓글이 커뮤니티 가이드를 위반하여 삭제되었습니다."
    } else if (text.type === "reporter") {
      id += `의 ${text.reporter.account.id} 댓글이 커뮤니티 가이드를 위반하여 삭제하였습니다.`
    }
  } else {
    id += `의 ${text.reporter.account.id} 댓글이 커뮤니티 가이드를 위반한 내용을 찾을 수 없어 삭제하지 않았습니다. `
  }

  return id
}
