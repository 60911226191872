import api from "./apis.js"
import address, { API_URI } from "./address.js"
import cookie from "react-cookies"
import { arrayEdit, getRank, getJson } from "utils"
import axios from "axios"
import { Shop, Subscribe, Lesson, AlarmVO, CouponVO } from "class"
import dayjs from "dayjs"
import { getExpire } from "utils"
import { navigate } from "gatsby"

//회원가입
const signUp = async data => {
  const result = await api.post(`${address.signUp}`, data)
  console.log(result)
  return result?.data?.code !== 0 || result.status !== 200 ? true : false
  //오류발생시 true리턴
}

//아이디 중복
const idOverlap = async id => {
  const result = await api.get(`${address.idOverlap}` + id)
  console.log(result)
  if (result?.data?.code !== 0 || result.status !== 200) return
  return result.data.data.exist ?? true
}

//모바일 인증번호 받기
const getMobileConfirm = async data => {
  const result = await api.post(`${address.getMobileConfirm}`, data)
  console.log(result)
  if (result?.data?.code !== 0 || result.status !== 200) return
  return result.data.data.phone
}

//모바일 인증하기
const mobileConfirm = async data => {
  const result = await api.post(`${address.mobileConfirm}`, data)
  console.log(result)
  if (result?.data?.code !== 0 || result.status !== 200) return
  return result.data.data.phone
}

// 로그인
const login = async data => {
  const result = await api.post(`${address.login}`, data)
  const autoLoginCheck = document.getElementById("autoLoginCheck")
  const { checked } = autoLoginCheck ?? false
  let profile = null

  if (
    result?.data?.code !== 0 ||
    result.status !== 200 ||
    !result.data.data.token
  )
    return [profile, result.data.desc]

  profile = result.data.data.account.Profile
  const limit = checked ? dayjs().add(1, "week") : undefined
  const expires = limit?.toDate()

  //expires:

  cookie.save("expires", expires?.toISOString(), {
    path: "/",
    expires,
  })

  cookie.save(
    "access_token",
    {
      token: result.data.data.token,
      name: result.data.data.account.name,
      rank: result.data.rank ?? 0,
      subscribe_no: result.data.data.account.subscribe_no, // 구독여부판단
    },
    {
      path: "/",
      expires,
    }
  )
  cookie.save(
    "profile",
    {
      uuid: profile?.uuid ?? "my_uuid",
      icon: profile?.icon_no,
      name: profile?.name,
    },
    {
      path: "/",
      expires,
    }
  )

  return [profile, result.data.desc]
}

// 현재 접속 유저 정보 갱신
const setCurrentUser = async data => {
  console.log(data)
  const result = await api.post(`${address.reviseFingerprint}`, data)
  console.log(result)

  if (result.status != 200 || result.data.code !== 0) return result

  return result
}

//프로필 등록
const registerProfile = async data => {
  const result = await api.post(`${address.registerProfile}`, data)

  console.log(result)

  if (result?.status !== 200) return
  return result.data
}

//프로필 읽기
const readProfile = async data => {
  const result = await api.get(`${address.readProfile}`)

  if (result?.status !== 200) return false
  const option = getExpire("expires")

  cookie.save(
    "profile",
    {
      uuid: result?.data?.data?.profile?.uuid ?? "my_uuid",
      icon: result?.data?.data?.profile?.icon_no,
      name: result?.data?.data?.profile?.name,
    },
    option
  )
  return result.data.data["profile"]
}

//프로필 아이콘 수정
const reviseIcon = async data => {
  const result = await api.put(`${address.reviseIcon}`, data)
  console.log(result)
  if (result?.status !== 200) return false
  return result.data
}

//프로필 이름 수정
const reviseName = async data => {
  const result = await api.put(`${address.reviseName}`, data)
  if (result?.status !== 200) return false
  return result.data
}

//프로필 핸드폰 수정
const revisePhone = async data => {
  const result = await api.put(`${address.revisePhone}`, data)
  if (result?.status !== 200) return false
  return result.data
}

//프로필 이메일 수정
const reviseEmail = async data => {
  const result = await api.put(`${address.reviseEmail}`, data)
  if (result?.status !== 200) return false
  return result.data
}

//프로필 관심주제 수정
const reviseSubject = async data => {
  const result = await api.put(`${address.reviseSubject}`, data)
  if (result?.status !== 200) return false
  return result.data
}

// 아이디 찾기 - 모바일
const idmobile = async data => {
  const result = await api.post(`${address.idmobile}`, data)
  console.log(result)
  if (result?.status !== 200 || !result.data?.desc) return
  return result.data
}

// 아이디 찾기 - 이메일
const idemail = async data => {
  const result = await api.post(`${address.idemail}`, data)
  console.log(result)
  if (result?.status !== 200 || !result.data?.desc) return
  return result.data
}

// 비밀번호 찾기(리셋) - 이메일
const pwdemail = async data => {
  const result = await api.post(`${address.pwdemail}`, data)
  console.log(result)
  if (result?.status !== 200 || !result.data?.desc) return
  return result.data
}

// 비밀번호 찾기(리셋) - 모바일
const pwdmobileReset = async data => {
  const result = await api.post(`${address.pwdmobileReset}`, data)
  console.log(result)
  if (result?.status !== 200 || !result.data?.desc) return
  return result.data
}
// 비밀번호 찾기 체크 - 모바일
const pwdmobileCheck = async data => {
  const result = await api.post(`${address.pwdmobileCheck}`, data)
  console.log(result)
  if (result?.status !== 200 || !result.data?.desc) return
  return result.data
}

// 쿠폰 가져오기
const getCoupon = async () => {
  const result = await api.get(`${address.coupon}`)
  console.log(result)
  if (
    result?.status !== 200 ||
    result.data?.code !== 0 ||
    !result.data.data?.couponPost?.length
  )
    return []
  const currentDate = dayjs().unix()

  return result.data.data.couponPost
    .filter(
      post =>
        post.Coupon.end_valid > currentDate &&
        !post.used &&
        post.Coupon.status === 0
    )
    .map(v => new CouponVO(v))
}

//카드 리스트
const getCardStoryList = async (page, take, order) => {
  const result = await api.get(
    `${address.cardStoryList}${setQuery(page, take, order)}`
  )
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result.data.data)

  return result.data.data
}

//스토리 상세 가져오기
const detailedClassinfo = async data => {
  console.log(111111111)
  const result = await api.get(`${address.detailedClassinfo}` + data)

  if (result?.status !== 200 || !result.data?.desc) return
  return result.data.data.story
}

//교실이야기 상세 가져오기
const detailedRoominfo = async data => {
  console.log(data)
  const result = await api.get(`${address.detailedRoominfo}` + data)
  console.log(result)

  if (result?.status !== 200 || !result.data?.desc) return
  return result.data.data.edu_talk
}

// 카드 상세
const getCardStoryDetail = async postNum => {
  const result = await api.get(`${address.cardStoryDetail}` + postNum)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data.illust
}

// 찜하기
const onLike = async (keyword, data) => {
  const result = await api.post(`${address.like[keyword]}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return false
  return true
}

//교실 이야기 가져오기
const getExhibitionDetail = async data => {
  const result = await api.get(`${address.exhibitionDetail}` + data)

  if (result?.status !== 200 || !result.data?.desc) return
  return result.data.data
}

//교실 이야기 리스트
const getClassRoomList = async (page, take, order) => {
  const result = await api.get(
    `${address.classRoomList}${setQuery(page, take, order)}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

//스토리 리스트
const getStoryList = async (page, take, order, main, sub) => {
  const result = await api.get(
    `${address.storyList}${setQuery(page, take, order, main, sub)}${setCategory(
      main,
      sub
    )}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

//관심주제 리스트
const getInterestList = async (page, take, order, topic) => {
  console.log(topic)

  const result =
    topic == 0
      ? await api.get(`${address.storyList}${setQuery(page, take, order)}`)
      : await api.get(
          `${address.storyList}${setQuery(
            page,
            take,
            order
          )}&story_topics=${topic}`
        )

  console.log(`${address.storyList}${setQuery(page, take, order, topic)}`)

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

//기획전 리스트
const getExhibitionList = async (page, take, order) => {
  const result = await api.get(
    `${address.exhibitionList}${setQuery(page, take, order)}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

//연관 스토리
const associatedStory = async (no, keyword) => {
  console.log(keyword)
  const result = await api.get(`${address.associated[keyword]}${no}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.pageList
}

// 비디오 리스트

const getVideoList = async (page, take, order) => {
  const result = await api.get(
    `${address.videoList}${setQuery(page, take, order)}`
  )
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data
}

//비디오 상세

const getVideoDetail = async postNum => {
  const result = await api.get(`${address.videoDetail}` + postNum)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.video
}

// 당신을 위한 스토리 리스트
const getYourStoryList = async (page, take, order) => {
  const result = await api.get(
    `${address.yourStoryList}${setQuery(page, take, order)}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

// 월간 피치서가 리스트
const getMonthList = async (page, take, order) => {
  const result = await api.get(
    `${address.monthList}${setQuery(page, take, order)}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data
}

// 월간 피치서가 상세
const getMonthDetail = async postNum => {
  const result = await api.get(`${address.monthDetail}${postNum}`)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.bookstory
}

//월간 피치서가 연관 스토리
const MonthAssociatedStory = async (book_no, content_no) => {
  const result = await api.get(
    `${address.associated.month}&book_number=${book_no}&content_number=${content_no}`
  )
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.pageList
}

//소식 리스트
const getNoticeList = async (page, take, order) => {
  const result = await api.get(
    `${address.noticeList}${setQuery(page, take, order)}`
  )

  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)
  // 발행중지 status확인
  return result.data.data
}
//소식 디테일

const getNoticeDetail = async postNum => {
  const result = await api.get(`${address.noticeDetail}${postNum}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.news
}

//배너 정보 가져오기

const getBannerDetail = async position => {
  const result = await api.get(`${address.bannerDetail}${position}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.banners
}

const getTermDetail = async kind => {
  const result = await api.get(`${address.termDetail}${kind}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.terms
}

const getFaqList = async (frequecy, category) => {
  const faq_category = category === undefined ? "" : `&faq_category=${category}`
  const faq_frequecy = frequecy === undefined ? "" : `&faq_frequecy=${frequecy}`

  const result = await api.get(
    `${address.faqList}${faq_category}${faq_frequecy}`
  )

  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const getfaqDetail = async num => {
  const result = await api.get(`${address.faqDetail}${num}`)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.faq?.bodyText ?? ""
}
//1대1문의
const contact = async data => {
  const result = await api.post(`${address.contact}`, data)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.question
}
//1대1문의 목록
const getContactList = async (page, take, order, time) => {
  const result = await api.get(
    `${address.contactList}${setQuery(page, take, order)}${setTimeQuery(time)}`
  )

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data
}

//1대1문의 상세

const getContactDetail = async num => {
  const result = await api.get(`${address.contactDetail}${num}`)

  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data.question
}

const getAnswerDetail = async num => {
  const result = await api.get(`${address.answerDetail}${num}`)

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data
}
//프론트 정보
const getFront = async keyword => {
  const result = await api.get(`${address.front[keyword]}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data
}

const lessonList = async (page, take, order, option) => {
  console.log(setOption(option))

  const result = await api.get(
    `${address.lessonList}${setQuery(page, take, order)}${setOption(option)}`
  )

  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data
}

//스토리 검색
const getSearchList = async (
  page,
  take,
  order,
  type,
  tagNumber,
  keyword,
  checkedList
) => {
  console.log(address.search)

  const result = await api.get(
    `${address.search}${type}?${setQuery(
      page,
      take,
      order
    )}&search_number=${tagNumber}&search_keyword=${keyword}${subCate(
      type,
      checkedList
    )}`
  )

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

//수업나눔 추가
const lessonWrite = async data => {
  const result = await api.post(`${address.lessonWrite}`, data)

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.edu_nanum?.nanum_no
}

//수업나눔 상세
const lessonDetail = async number => {
  const result = await api.get(`${address.lessonDetail}${number}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  console.log(result)

  return result.data.data?.edu_nanum
}
const getLessonTitle = async number => {
  const result = await api.get(`${address.getLessonTitle}${number}`)
  console.log(result)
  if (
    result?.status !== 200 ||
    result.data?.code !== 0 ||
    !result.data.data?.alarams.length
  )
    return

  return result.data.data?.alarams[0].title
}
const lessonMod = async data => {
  const result = await api.post(`${address.lessonMod}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.edu_nanum?.nanum_no
}
const lessonDetail_test = async number => {
  const result = await api.get(`${address.lessonDetail}${number}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  const edunanum = result.data.data?.edu_nanum
  //Lesson
  if (edunanum) return await new Lesson(edunanum).init()
}

//수업나눔 삭제
const lessonRemove = async data => {
  const result = await api.delete(`${address.lessonRemove}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return true
}

//댓글 작성
const commentInsert = async data => {
  const result = await api.post(`${address.comment}`, data)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return true
}

//댓글 수정
const commentModify = async data => {
  const result = await api.put(`${address.comment}`, data)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return true
}

//댓글 삭제
const commentDel = async data => {
  const result = await api.delete(`${address.comment}`, data)
  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return
  return true
}

//댓글 리스트
const commentList = async number => {
  const result = await api.get(`${address.commentList}${number}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.edu_nanum?.comments ?? []
}

const commentDeclare = async data => {
  const result = await api.post(`${address.commentDeclare}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data?.complain?.complain_no ? true : false
}

const getIntro = async () => {
  const result = await api.get(`${address.intro}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.intro?.bodyText
}

const setting = async (keyword, data) => {
  const result = await api.get(`${address.setting[keyword]}`, data)

  console.log(result.data.data)
  return result.data.data
}

const reviseSetting = async (keyword, data) => {
  console.log(data)

  const result = await api.put(`${address.setting[keyword]}`, data)
  console.log(result)

  console.log(result.data.data)
  return result.data
}

const groupJoin = async data => {
  const result = await api.post(`${address.groupJoin}`, data)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return true
}

const addScb = async data => {
  const result = await api.post(`${address.scb}`, data)
  console.log(result)

  if (result.data.code === 10000) {
    return true
  }

  if (result?.status !== 200 || result.data?.code !== 0) return

  return true
}
const getFooter = async () => {
  const result = await api.get(`${address.footer}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const getscbDetail = async () => {
  const result = await api.get(`${address.scbDetail}`)

  console.log(result)

  // 구독정보가 없으면 아직 입력안한상태
  if (result?.status !== 200 || result.data?.code !== 0) return

  return new Subscribe(result.data.data.subscribe)
}
const getShopDetail = async () => {
  const result = await api.get(`${address.shopDetail}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return new Shop(result.data.data.shop)
}
//결제

const payTrial = async data => {
  const result = await api.post(`${address.pay.trial}`, data)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data.subscribe.subscribe_no ? true : false
}

const payBegin = async data => {
  const result = await api.post(`${address.pay.begin}`, data)

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return {
    merchant_uuid: result.data.data.billing.merchant_uuid,
    billing_no: result.data.data.billing.billing_no,
    customer_uuid: result.data.data.billing?.customer_uuid,
  }
}

const payEnd = async data => {
  const result = await api.post(`${address.pay.end}`, data)

  console.log(result)

  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data.subscribe.subscribe_no ? true : false
}

const payCancel = async data => {
  const result = await api.post(`${address.pay.cancel}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
}
const payList = async () => {
  const result = await api.get(`${address.pay.list}`)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result.data.data.billings
}
const scbHistory = async () => {
  const result = await api.get(`${address.scbHistory}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data.billings
}
const payDetail = async number => {
  const result = await api.get(`${address.pay.detail}${number}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
}
const billingDetail = async number => {
  const result = await api.get(`${address.billingDetail}${number}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data.billing
}

const scbCancel = async data => {
  const result = await api.post(`${address.scbCancel}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return true
}
const scbCloseCancel = async data => {
  const result = await api.post(`${address.scbCloseCancel}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return true
}
const billingMethod = async number => {
  const result = await api.get(`${address.billingMethod}${number}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data.method
}
const eduAttachDel = async data => {
  const result = await api.delete(`${address.eduAttach}`, data)
  if (result?.status !== 200 || result.data?.code !== 0) return false
  return result.data.data?.attach?.seq_no ? true : false
}
const eduAttachAdd = async data => {
  const result = await api.post(`${address.eduAttach}`, data)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.attach?.seq_no ? true : false
}
const addStorage = async data => {
  const result = await api.post(`${address.addStorage}`, data)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data?.storage[0]?.data_uuid
}
//나의서재
//최근 본 스토리
const getRecentStory = async () => {
  const result = await api.get(`${address.myShelf.recentStory}`)
  if (result?.status !== 200 || result.data?.code !== 0) return []
  return result?.data?.data?.recent ?? []
}
//내가 찜한 스토리
const getLikeStory = async (page, take, order, table) => {
  const result = await api.get(
    `${address.myShelf.likePost}${setQuery(
      page,
      take,
      order
    )}&table_number=${table}`
  )
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return []
  return result?.data?.data ?? []
}

//최근 본 수업나눔
const getRecentLesson = async () => {
  const result = await api.get(`${address.myShelf.recentLesson}`)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return []
  return result?.data?.data?.recent ?? []
}
//내가 작성한 수업나눔
const getWriteLesson = async (page, take, order) => {
  const result = await api.get(
    `${address.myShelf.writedClass}${setQuery(page, take, order)}`
  )
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return []
  return result?.data?.data ?? []
}
const bannerHit = async data => {
  const result = await api.post(`${address.bannerHit}`, data)

  if (result?.status !== 200 || result.data?.code !== 0) return []
  return result?.data?.data
}
//알람
const getAlarm = async () => {
  const result = await api.get(`${address.alarmList}`)
  if (result?.status !== 200 || result.data?.code !== 0) return []

  console.log(result?.data?.data?.alarams)

  return result?.data?.data?.alarams
    .filter(dvo => dvo.isNew)
    .map(dvo => new AlarmVO(dvo))
}

const AlarmCheck = async data => {
  const result = await api.put(`${address.alarmCheck}`, data)
  if (result?.status !== 200 || result.data?.code !== 0) return false
  return true
}
const getPopup = async () => {
  const result = await api.get(`${address.getPopup}`)

  if (
    result?.status !== 200 ||
    result.data?.code !== 0 ||
    !result?.data?.data?.popup
  )
    return []

  const currentDate = dayjs().unix()

  return result.data.data.popup.filter(
    ({ publishEndTime }) => currentDate < publishEndTime
  )
}

const getHiddenProject = async number => {
  const result = await api.get(`${address.hiddenProject}${number}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const getHiddenProjectList = async (page, take, order) => {
  const result = await api.get(
    `${address.hiddenProjectList}${setQuery(page, take, order)}`
  )
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const getHiddenStory = async number => {
  const result = await api.get(`${address.hiddenStory}${number}`)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const getHiddenStoryList = async (page, take, order, number) => {
  const result = await api.get(
    `${address.hiddenStoryList}${setQuery(
      page,
      take,
      order
    )}&story_hidden=${number}`
  )
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data
}

const loginCheck = async () => {
  const { token } = getJson("access_token") ?? {}
  console.log(token)
  try {
    await axios({
      method: "GET",
      url: API_URI + "v2/profile/detail",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return true
  } catch ({ response }) {
    return response.status !== 401
  }
}

const getLanding = async () => {
  const result = await api.get(`${address.getLanding}`)
  console.log(911911, result.data.data.landing)
  if (result?.status !== 200 || result.data?.code !== 0) return
  return result.data.data.landing
}
const insertLog = async data => {
  data.body.client_body = JSON.stringify(data.body.client_body)

  const result = await api.post(`${address.insertLog}`, data)
  console.log(result)
  if (result?.status !== 200 || result.data?.code !== 0) return

  return result
}

const method = {
  insertLog,
  getLessonTitle,
  loginCheck,
  getPopup,
  payTrial,
  getAlarm,
  AlarmCheck,
  bannerHit,
  getWriteLesson,
  getRecentLesson,
  getLikeStory,
  getRecentStory,
  addStorage,
  eduAttachDel,
  eduAttachAdd,
  lessonMod,
  lessonDetail_test,
  scbCloseCancel,
  billingMethod,
  billingDetail,
  scbHistory,
  scbCancel,
  payList,
  payDetail,
  signUp,
  idOverlap,
  getMobileConfirm,
  mobileConfirm,
  registerProfile,
  login,
  idmobile,
  idemail,
  pwdemail,
  pwdmobileReset,
  pwdmobileCheck,
  setCurrentUser,
  readProfile,
  reviseIcon,
  reviseName,
  revisePhone,
  reviseEmail,
  reviseSubject,
  getCoupon,
  getCardStoryList,
  detailedClassinfo,
  getCardStoryDetail,
  detailedRoominfo,
  onLike,
  getExhibitionDetail,
  getClassRoomList,
  getExhibitionList,
  associatedStory,
  getVideoList,
  getVideoDetail,
  getStoryList,
  getYourStoryList,
  getInterestList,
  getMonthList,
  getMonthDetail,
  MonthAssociatedStory,
  getNoticeList,
  getNoticeDetail,
  getBannerDetail,
  getTermDetail,
  getFaqList,
  getfaqDetail,
  contact,
  getContactList,
  getContactDetail,
  getAnswerDetail,
  getFront,
  lessonList,
  getSearchList,
  lessonWrite,
  lessonDetail,
  lessonRemove,
  commentInsert,
  commentList,
  commentModify,
  commentDel,
  commentDeclare,
  getIntro,
  setting,
  groupJoin,
  reviseSetting,
  addScb,
  getFooter,
  getscbDetail,
  getShopDetail,
  payBegin,
  payEnd,
  payCancel,
  getHiddenProject,
  getHiddenProjectList,
  getHiddenStoryList,
  getHiddenStory,
  getLanding,
}

export default method

export const setQuery = (page, take, order) => {
  return order
    ? `page_number=${page}&take_number=${take}&order_number=${order}`
    : `page_number=${page}&take_number=${take}`
}
export const setCategory = (main, sub) => {
  if (sub == 0 && main == 0) return ""

  if (main) {
    return `&story_main=${main}`
  } else if (sub) {
    return `&story_sub=${sub}`
  } else {
    return ""
  }
}

const setTimeQuery = time => {
  const { begin, end } = time

  return begin && end
    ? `&question_begintime=${begin}&question_endtime=${end}`
    : ""
}

const setOption = ({ course, curriculum, kind }) => {
  const courseQ =
    course && course.length > 0
      ? `&nanum_course=${arrayEdit(course, "%23")}`
      : ""
  const curriculumQ =
    curriculum && curriculum.length > 0
      ? `&nanum_curriculum=${arrayEdit(curriculum, "%23")}`
      : ""
  const kindQ =
    kind && kind.length > 0 ? `&nanum_kind=${arrayEdit(kind, "%23")}` : ""

  return courseQ + curriculumQ + kindQ
}

const subCate = (type, checkedList) => {
  let result = ""
  if (type === "story") {
    if (checkedList?.topic?.length >= 1)
      result += `&story_topics=${arrayEdit(checkedList.topic, "%23")}`
    if (checkedList?.subCategory?.length >= 1)
      result += `&story_sub=${checkedList?.subCategory[0]}`
  }

  return result
}
