import axios from "axios"
import { API_URI } from "./address.js"
import { getJson } from "utils"
import configureStore from "store"
import { setContextError } from "store/modules/errorReducer.js"
import cookie from "react-cookies"
import { logOut } from "utils"
import { getExpire } from "utils"

const apiFunction =
  method =>
  async (url = "", { body = {}, headers = {}, loading = false } = {}) => {
    const access = getJson("access_token")

    const token = access?.token ?? ""
    //
    // const UserContext = React.createContext(userContextTemplate)
    const option = getExpire("expires")
    try {
      console.log("data.body", body)
      console.log(API_URI + url)
      // setIsload(true)
      const result = await axios({
        method: method,
        url: API_URI + url,
        data: body,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        withCredentials: true,
      })

      setIsload(false)
      // const limit = cookie.load("expires", { path: "/" })
      // const expires = limit ? dayjs(limit).toDate() : ""
      // const option = getExpire("expires")

      if (token) {
        cookie.save(
          "access_token",
          {
            ...access,
            rank: result.data.rank ?? 0,
          },
          option
        )
      }

      return result
    } catch ({ response }) {
      // err에서 분할구조할당
      if (response) {
        console.log(response.data.message)
        switch (response.status) {
          case 400:
            console.log(response.data.message)
            console.warn("400에러", response.data)

            break
          case 401:
            console.log(response.data.message)
            console.log(url)
            console.warn("401에러", response.data)

            if (response.data.message === "login required") logOut()

            configureStore.dispatch(setContextError(response.data.message))

            break
          case 403:
            console.log(response.data.message)
            console.warn("403에러", response.data)

            break
          case 404:
            console.log(response.data.message)
            console.warn("404에러", response.data)

            break
          default:
            return response
        }
      }
    }
    //  finally {
    //   if (loading) {
    //     configureStore.dispatch(setLoading(false))
    //   }
    // }
  }

const api = {
  post: apiFunction("POST"),
  get: apiFunction("GET"),
  delete: apiFunction("DELETE"),
  patch: apiFunction("PATCH"),
  put: apiFunction("PUT"),
}

export default api

const setIsload = (isLoad = false) => {
  // if (isLoad) console.log("test Loading")
}
