import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
  useMemo,
} from "react"
import DaumPostcode from "react-daum-postcode"
import modalContext from "context/modalContext"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

export const useRefObj = (object = {}) => {
  const ref = useRef(object)

  const setRef = useCallback((c, name) => {
    console.log(c)
    const refName = name ?? c?.id ?? Symbol()

    ref.current[refName] = c
  }, [])

  // ref.current[Object.getOwnPropertySymbols(ref.current)[idx ?? 0]]

  return [ref, setRef]
}

export const useAddress = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const [address, setAddress] = useState("")

  const getAddress = useCallback(
    ({ address, addressType, buildingName, bname }) => {
      let fullAddress = address

      if (addressType === "R") {
        const extraAddress = [bname, buildingName].filter(v => v).join()

        if (extraAddress) fullAddress += ` (${extraAddress})`
      }

      setAddress(fullAddress)

      setmodalOpen(false)
      globalThis.history.back()
    },
    []
  )

  const onAddress = useCallback((style = {}) => {
    setModalMain(
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        onClick={() => setmodalOpen(false)}
      >
        <DaumPostcode
          onComplete={getAddress}
          style={{
            width: "60vw",
            position: "absolute",
            top: "0",
            bottom: "0",
            right: "0",
            left: "0",
            margin: "auto",
            ...style,
          }}
        />
      </div>
    )
    setmodalOpen(true)
  }, [])

  return [onAddress, address]
}

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState)

  const toggle = useCallback(() => setState(state => !state), [])

  return [state, toggle, setState]
}

export const useMenu = (cur = null) => {
  const curr = useRef(cur)
  const prev = useRef(null)

  const [state, setstate] = useState({
    current: curr.current,
    previous: prev.current,
  })

  const setCurrent = useCallback(
    current => {
      prev.current = curr.current
      curr.current = current

      setstate({
        current: curr.current,
        previous: prev.current,
      })

      return [prev.current, curr.current]
    },
    [curr, prev]
  )

  const onMenu = inMenu => {
    const outMenu = inMenu(curr.current)
    if (prev.current && prev.current !== curr.current) outMenu(prev.current)
  }

  return [state, setCurrent, onMenu]
}

export const useModal = () => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [modalMain, setModalMain] = useState("")

  const setModal = useMemo(
    () => ({ modalOpen, setmodalOpen, setModalMain }),
    [setmodalOpen, setModalMain, modalOpen]
  )

  return [modalOpen, modalMain, setModal]
}

export const useAlert = () => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMain, setAlertMain] = useState("")

  const setAlert = useMemo(
    () => ({ alertOpen, setAlertOpen, setAlertMain }),
    [setAlertOpen, setAlertMain, alertOpen]
  )

  return [alertOpen, alertMain, setAlert]
}

export const useTimer = () => {
  const [time, setTime] = useState("")

  useEffect(() => {
    const countdown = setInterval(() => {
      if (!time || typeof time === "string" || time <= 0) {
        clearInterval(countdown)
        return
      }
      setTime(state => state - 1)
    }, 1000)
    return () => clearInterval(countdown)
  }, [time])

  const timeOut = time === 0

  return [time, setTime, timeOut]
}

export const useStateObj = (initialState = {}) => {
  const [state, setstate] = useState(initialState)

  const setter = useCallback((key, value) => {
    setstate({
      ...state,
      [key]: value,
    })
  }, [])

  return [state, setter]
}

export const useScript = resourceUrl => {
  useEffect(() => {
    const script = document.createElement("script")
    script.async = false
    script.src = resourceUrl
    // script.dataset["api-host-url"] = process.env.API_URL
    // script.dataset["token-id"] = process.env.TOKEN_ID
    // script.dataset["s3-url-raw"] = process.env.S3_URL_RAW
    // script.dataset["s3-url-pub"] = process.env.S3_URL_PUB
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [resourceUrl])
}
// <script
//   type="text/javascript"
//   id="peach-bundle"
//   src="../static/bundle.js"
//   data-api-host-url={process.env.API_URL}
//   data-token-id={process.env.TOKEN_ID}
//   data-s3-url-raw={process.env.S3_URL_RAW}
//   data-s3-url-pub={process.env.S3_URL_PUB}
// />

export const useDep = (setFunc, dependency) => {
  const [state, setstate] = useState(null)
  console.log(dependency)

  useEffect(() => {
    setstate(setFunc)
  }, [...dependency])

  return [state, setstate]
}
