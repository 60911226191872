import { createAction, handleActions } from "redux-actions"
import produce from "immer"

const SETERROR = "store/module/classRoomReducer/SETERROR"
export const setContextError = createAction(SETERROR)

const initialState = {
  errorDesc: null,
}

const errorReducer = handleActions(
  {
    [SETERROR]: (state, { payload }) => {
      console.log(payload)
      return produce(state, draft => {
        draft.errorDesc = payload
      })
    },
  },
  initialState
)

export default errorReducer
// const getModal = msg => {
//   return (
//     <modalContext.Consumer>
//       {({ setmodalOpen, setModalMain }) => {
//         const { link, mainMsg, btnMsg } = errDesc.get(msg)
//         setmodalOpen(true)
//         setModalMain(
//           <div className={"modalText"}>
//             {mainMsg}
//             <div
//               onClick={() => {
//                 setmodalOpen(false)

//                 if (link) navigate(link)
//               }}
//             >
//               {btnMsg}
//             </div>
//           </div>
//         )
//       }}
//     </modalContext.Consumer>
//   )
// }
