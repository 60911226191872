import React, { useEffect, useState } from "react"
import * as styles from "components/tools/modal.module.css"

const Modal = props => {
  const { setmodalOpen, setModalMain } = props.setModal

  const onEnd = () => setmodalOpen(false)

  useEffect(() => {
    if (props.open) {
      globalThis.history.pushState({ page: "modal" }, "modal", "/modal")
      globalThis.addEventListener("popstate", onEnd)
    } else {
      globalThis.removeEventListener("popstate", onEnd)
      if (globalThis.location.href.includes("modal")) {
        globalThis.history.back()
      }
    }
  }, [props.open])

  return (
    <div
      className={
        props.open ? `${styles.open} ${styles.modal}` : `${styles.modal}`
      }
    >
      {props.open ? <>{props.children}</> : null}
    </div>
  )
}

export default Modal
