import React, { useEffect, useState } from "react"
import dayjs from "dayjs"

const NewBadge = props => {
  const [newBadge, setNewBadge] = useState(false)
  useEffect(() => {
    const resiterDate = dayjs.unix(props.registerTime)
    setNewBadge(resiterDate >= dayjs().subtract(7, "days"))
  }, [props])
  return (
    <div>
      {newBadge ? (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              backgroundColor: "#F1616ACC",
              width: "62px",
              height: "52px",
              color: "white",
              clipPath: " polygon(100% 100%, 100% 0, 0 0, 100% 100%)",

              zIndex: 1,
            }}
          >
            <div
              style={{
                marginLeft: "28px",
                marginTop: "5px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              New
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default NewBadge
