import React from "react"
import * as styles from "./menu.module.css"
import postHit from "images/postHit.png"
import postView from "images/postView.png"
import postAfterHit from "images/postAfterHit.png"

const Menu = props => {
  return (
    <div className={styles.con} onClick={props.onClick}>
      <div>
        <div>
          <img src={props.img}></img>
        </div>

        <div>
          <div>
            {props.news ? (
              <div>
                <p
                  style={{
                    margin: "0",
                    marginBottom: "15px",
                    marginTop: "60px",
                  }}
                >
                  {props.title}
                </p>
              </div>
            ) : (
              <div>
                <p style={{ margin: "0", marginBottom: "11px" }}>
                  {props.title}
                </p>
                <p
                  style={{
                    minHeight: "48px",
                    color: "#6f6f6f",
                    fontSize: 14,
                  }}
                >
                  {props.text}
                </p>
              </div>
            )}
          </div>

          <div>
            <div className={styles.sub}>
              {props.hit === undefined ? (
                ""
              ) : (
                <div>
                  <img src={postView} />
                  {props.hit}
                </div>
              )}
              {props.like === undefined ? (
                ""
              ) : (
                <div>
                  {props.selfLike == null ? (
                    <img src={postHit} />
                  ) : (
                    <img src={postAfterHit} />
                  )}

                  {props.like}
                </div>
              )}
            </div>
            <div className={styles.writerSub}>
              글쓴이 : {props.writer} {props.date ? `| ${props.date}` : ""}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default Menu
