import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const GETEXHIBITIONLIST = "store/module/classRoomReducer/EXHIBITIONLIST"
export const getExhibitionList = createAction(GETEXHIBITIONLIST)

export const getExhibitionListAsync = (page, take, order) => async dispatch => {
  const result = await api.getExhibitionList(page, take, order)

  if (!result?.pageList.length) return

  dispatch(getExhibitionList(result))
}

const initialState = {
  exhibitionList: [],
  total: 0,
}

const exhibitionReducer = handleActions(
  {
    [GETEXHIBITIONLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.exhibitionList = payload.pageList
        draft.total = payload.total
      })
    },
  },
  initialState
)

export default exhibitionReducer
