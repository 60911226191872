import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
import { HIDDENPAGE } from "utils/data"

const GETHIDDEN = "store/module/hiddenReducer/GETHIDDEN"
const GETHIDDENLIST = "store/module/hiddenReducer/GETHIDDENLIST"
const GETSTORY = "store/module/hiddenReducer/GETSTORY"
const GETSTORYLIST = "store/module/hiddenReducer/GETSTORYLIST"
const GETRELATEDLIST = "store/module/hiddenReducer/GETRELATEDLIST"

export const getHidden = createAction(GETHIDDEN)
export const getHiddenList = createAction(GETHIDDENLIST)
export const getHiddenStory = createAction(GETSTORY)
export const getHiddenStoryList = createAction(GETSTORYLIST)
export const getRelatedList = createAction(GETRELATEDLIST)

export const getHiddenAsync = number => async dispatch => {
  const result = await api.getHiddenProject(number)
  if (!result) return
  const { hidden } = result
  dispatch(getHidden(hidden))
}

export const getRelatedListAsync =
  (page, take, order, number) => async dispatch => {
    console.log(27, page, take, order, number)
    const result = await api.getHiddenStoryList(page, take, order, number)
    console.log(28, result)
    if (!result) return
    const { pageList } = result
    dispatch(getRelatedList(pageList))
  }

export const getHiddenProjectListAsync =
  (page, take, order) => async dispatch => {
    const result = await api.getHiddenProjectList(page, take, order)
    if (!result?.pageList.length) return
    dispatch(getHiddenList(result.pageList))
  }

export const getStoryAsync = number => async dispatch => {
  if (isNaN(Number(number))) {
    dispatch(getHiddenStory(HIDDENPAGE.user_error))
  } else {
    const result = await api.getHiddenStory(number)
    if (result && result?.story) {
      dispatch(getHiddenStory(result.story))
    } else {
      dispatch(getHiddenStory(HIDDENPAGE.server_error))
    }
  }
}

export const getHiddenStoryListAsync =
  (page, take, order, number) => async dispatch => {
    const result = await api.getHiddenStoryList(page, take, order, number)
    console.log(result)
    if (!result?.pageList.length) return
    result.pageList = result.pageList.filter(v => v.status !== 5)
    dispatch(getHiddenStoryList(result))
  }

const initialState = {
  data: {
    hidden: {
      list: [],
      detail: [],
    },
    story: {
      list: [],
      detail: HIDDENPAGE.initial,
      related: [],
    },
  },
}

const hiddenReducer = handleActions(
  {
    [GETHIDDEN]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.hidden.detail = payload
      })
    },
    [GETHIDDENLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.hidden.list = payload
      })
    },
    [GETSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.story.detail = payload
      })
    },
    [GETSTORYLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.story.list = payload
      })
    },
    [GETRELATEDLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.story.related = payload
      })
    },
  },
  initialState
)

export default hiddenReducer
