import React from "react"
import * as styles from "./story.module.css"
import { onFavor, offFavor } from "images"
import { storyCategory, table } from "utils/data.js"
import { getThumb } from "utils"
import { navigate } from "gatsby-link"
import Like from "components/tools/Like"
import NewBadge from "components/tools/newBadge"
import FreeBadge from "components/tools/freeBadge"

const Story = props => {
  const { main, sub } = storyCategory

  return (
    <div
      key={props.story_no}
      className={styles.con}
      onClick={e => {
        props.onClick
          ? props.onClick(e)
          : navigate(`/story/detail/?number=${props.story_no}`)
      }}
    >
      <div className={styles.imgDiv}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "250px",
          }}
        >
          {props.likeImg ? (
            props.likeImg
          ) : (
            <Like
              on={onFavor}
              off={offFavor}
              keyword={"story"}
              isLike={props?.Like}
              style={{ img: { margin: "6px" } }}
              postNumber={props.story_no}
            />
          )}
          {props.free ? (
            <FreeBadge free={props.free} />
          ) : (
            <NewBadge registerTime={props.registerTime} />
          )}
        </div>
        <img src={getThumb(props.thumb_title_uuid)} />
      </div>
      <div>
        <div>{props.title}</div>
        <div>{props.introText}</div>
        <div>
          <div>{main.get(props.cateMain)}</div>
          <div>{sub.get(props.cateSub)}</div>
        </div>
      </div>
    </div>
  )
}

export default Story
