import dayjs from "dayjs"

export default class Subscribe {
  constructor(data) {
    // 구독안한 회원일때 정보

    this.isProfile = data.SubscribeProfile ? true : false
    if (this.isProfile) {
      this.address = data.SubscribeProfile.address
      this.name = data.SubscribeProfile.name
      this.phone = data.SubscribeProfile.phone
      this.register = data.SubscribeProfile.registerTime
    }

    this.billing_no = data.billing_no
    this.goods_no = data.goods_no
    this.owner = data.owner
    this.refund_no = data.refund_no
    this.schedule_no = data.schedule_no
    this.isScb = getIsScb(data.subscribe_begin, data.subscribe_end)
    this.isRefund = this.refund_no !== 0 // true면 환불걸린상태
    this.isSchedule = data.schedule_no !== 0 // true면 예약 걸린상태
    //구독자의 경우
    this.begin = data.subscribe_begin
    this.end = data.subscribe_end
    //한번도 구독 x , 구독만료
    this.isBeginner = this.billing_no === 0 // 한번도 구독안한상태
    this.isExpired = !this.isScb && !this.isBeginner // 구독만료
  }
}

const getIsScb = (begin, end) => {
  // if (!begin) return false
  const is_invalid_time = dayjs().unix() < end && begin < end //
  return is_invalid_time
}
