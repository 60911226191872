import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"

const GETFOOTER = "store/module/footerReducer/GETFOOTER"
const GETFAMILES = "store/module/footerReducer/GETFAMILES"

export const getFooter = createAction(GETFOOTER)
export const getFamiles = createAction(GETFAMILES)

export const getFooterAsync = () => async dispatch => {
  const result = await api.getFooter()

  if (!result) return

  const { footer, familes } = result

  dispatch(getFooter(footer))
  dispatch(getFamiles(familes))
}

const initialState = {
  data: {
    footer: {},
    familes: [],
  },
}

const footerReducer = handleActions(
  {
    [GETFOOTER]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.footer = payload
      })
    },
    [GETFAMILES]: (state, { payload }) => {
      return produce(state, draft => {
        draft.data.familes = payload
      })
    },
  },
  initialState
)

export default footerReducer
