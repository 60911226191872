import { createContext } from "react"

const alertContext = createContext({
  setAlertDownOpen: () => {},

  alertOpen: false,
  setAlertOpen: () => {},

  setAlertMain: () => {},
})

export default alertContext
