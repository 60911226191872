import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"

const GETCARDLIST = "store/module/postReducer/GETCARDLIST"
const GETVIDEOLIST = "store/module/postReducer/GETVIDEOLIST"
const GETMONTHLIST = "store/module/postReducer/GETMONTHLIST"
const GETNOTICELIST = "store/module/postReducer/GETNOTICELIST"
// const GETCsLIST = "store/module/postReducer/GETCSLIST"
const GETCSLIST = "store/module/postReducer/GETCSLIST"
const GETFRECSLIST = "store/module/postReducer/GETFRECSLIST"
const GETCONTACTLIST = "store/module/postReducer/GETCONTACTLIST"
const GETLESSONLIST = "store/module/postReducer/GETLESSONLIST"

export const getCardList = createAction(GETCARDLIST)
export const getVideoList = createAction(GETVIDEOLIST)
export const getMonthList = createAction(GETMONTHLIST)
export const getNoticeList = createAction(GETNOTICELIST)
// export const getCsList = createAction(GETCSLIST)
export const getCsList = createAction(GETCSLIST)
export const getFreList = createAction(GETFRECSLIST)
export const getContactList = createAction(GETCONTACTLIST)
export const getLessonList = createAction(GETLESSONLIST)

export const getLessonListAsync =
  (page, take, order, option) => async dispatch => {
    const result = await api.lessonList(page, take, order, option)

    dispatch(getLessonList(result))
  }

export const getCsListAsync = (fre, cate) => async dispatch => {
  const result = await api.getFaqList(fre, cate)

  fre ? dispatch(getFreList(result)) : dispatch(getCsList(result))
}

//
import dayjs from "dayjs"
export const getContactListAsync =
  (page, take, order, time) => async dispatch => {
    const result = await api.getContactList(page, take, order, time)
    console.log(dayjs.unix(time.begin).format("YYYY - MM - DD"))
    console.log(dayjs.unix(time.end).format("YYYY - MM - DD"))
    console.log(result)

    dispatch(getContactList(result))
  }

export const getNoticeListAsync = (page, take, order) => async dispatch => {
  const result = await api.getNoticeList(page, take, order)

  if (!result?.pageList.length) return

  dispatch(getNoticeList(result))
}

export const getCardListAsync = (page, take, order) => async dispatch => {
  const result = await api.getCardStoryList(page, take, order)

  if (!result?.pageList.length) return

  dispatch(getCardList(result))
}

export const getVideoListAsync = (page, take, order) => async dispatch => {
  const result = await api.getVideoList(page, take, order)
  if (!result?.pageList.length) return
  dispatch(getVideoList(result))
}

export const getMonthListAsync = (page, take, order) => async dispatch => {
  const result = await api.getMonthList(page, take, order)
  if (!result?.pageList.length) return
  dispatch(getMonthList(result))
}

const initialState = {
  card: {
    cardList: [],
    cardTotal: 0,
  },
  video: {
    videoList: [],
    videoTotal: 0,
  },
  month: {
    monthList: [],
    monthTotal: 0,
  },
  notice: {
    noticeList: [],
    noticeTotal: 0,
  },
  cs: {
    csList: [],
    freList: [],
  },
  contact: {
    contactList: [],
    contactTotal: 0,
  },
  lesson: {
    lessonList: [],
    lessonTotal: 0,
  },
}
const postReducer = handleActions(
  {
    [GETCARDLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.card.cardList = payload.pageList
        draft.card.cardTotal = payload.total
      })
    },
    [GETVIDEOLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.video.videoList = payload.pageList
        draft.video.videoTotal = payload.total
      })
    },
    [GETMONTHLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.month.monthList = payload.pageList
        draft.month.monthTotal = payload.total
      })
    },
    [GETNOTICELIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.notice.noticeList = payload.pageList
        draft.notice.noticeTotal = payload.total
      })
    },
    [GETCSLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.cs.csList = payload.pageList
      })
    },
    [GETFRECSLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.cs.freList = payload.pageList
      })
    },
    [GETCONTACTLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.contact.contactList = payload?.pageList ?? []
        draft.contact.contactTotal = payload?.total ?? 0
      })
    },
    [GETLESSONLIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.lesson.lessonList = payload?.pageList ?? []
        draft.lesson.lessonTotal = payload?.total ?? 0
      })
    },
  },
  initialState
)

export default postReducer
