import api from "apis"
import React from "react"
import { getJson, getThumb, getDateYMD } from "utils"
import { getRemoveUI } from "utils"
export default class CommentMap {
  constructor(db) {
    this.nanum_no = 0
    //
    this.isParent = db.parent_no === 0
    this.isDeleted = db.deleted
    this.comment_no = db.comment_no
    this.parent_no = db.parent_no

    this.profile = db.Profile
    this.loginProfile = {}
    //
    this.isMyComment = false

    //
    this.content = db.bodyText
    this.register = {
      year: getDateYMD(db.registerTime, "YYYY.MM.DD"),
      hour: getDateYMD(db.registerTime, "HH:MM"),
    }
    //
    this.setmodalOpen = () => {}
    this.setModalMain = () => {}
    this.onRender = () => {}
    //
  }
  init() {
    this.isMyComment =
      (this.loginProfile?.uuid ?? "login_uuid") ===
      (this.profile?.uuid ?? "writer_uuid")
  }

  async recommnet(value, finish) {
    console.log(value)
    const result = await api.commentInsert({
      body: {
        nanum_number: this.nanum_no,
        comment_parent: this.comment_no,
        comment_body: value,
      },
    })
    if (result) {
      finish()
      this.onRender()
    }
  }

  async modify(value, finish) {
    const result = await api.commentModify({
      body: {
        nanum_number: this.nanum_no,
        comment_number: this.comment_no,
        comment_body: value,
      },
    })

    if (result) {
      finish()
      this.onRender()
    }
  }

  async delete() {
    getRemoveUI(
      this.setModalMain,
      this.setmodalOpen,
      " 댓글을 정말 삭제하시겠습니까?",
      async () => {
        const result = await api.commentDel({
          body: {
            nanum_number: this.nanum_no,
            comment_number: this.comment_no,
          },
        })

        if (result) this.onRender()
      }
    )
  }

  async declare(id) {
    const result = await api.commentDeclare({
      body: {
        nanum_number: this.nanum_no,
        comment_number: this.comment_no,
        cause_number: id,
      },
    })

    if (result) {
      alert("신고 되었습니다.")
      // this.setmodalOpen(false)
    } else {
      alert("이미 신고된 댓글입니다.")
    }
  }
}
