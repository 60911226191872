import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const GETCATEGORYLIST = "store/module/categoryReducer/GETCATEGORYLIST"
export const getCategoryList = createAction(GETCATEGORYLIST)

export const getCategoryListAsync =
  (page, take, order, main, sub) => async dispatch => {
    const result = await api.getStoryList(page, take, order, main, sub)

    console.log("checking")
    if (!result?.pageList.length) {
      dispatch(getCategoryList({ pageList: [], total: 0 }))
    } else {
      dispatch(getCategoryList(result))
    }
  }

const initialState = {
  categoryList: [],
  total: 0,
}

const categoryReducer = handleActions(
  {
    [GETCATEGORYLIST]: (state, { payload }) => {
      state = initialState

      return produce(state, draft => {
        draft.categoryList = payload.pageList
        draft.total = payload.total
      })
    },
  },
  initialState
)

export default categoryReducer
