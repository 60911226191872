import React, { useEffect, useState } from "react"

const FreeBadge = props => {
  const [freeBadge, setFreeBadge] = useState(false)
  console.log(freeBadge)
  useEffect(() => {
    setFreeBadge(e => (e = props.free))
  }, [props])
  return (
    <>
      {freeBadge ? (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div
            style={{
              backgroundColor: "#0B4247",
              width: "62px",
              height: "52px",
              color: "white",
              clipPath: " polygon(100% 100%, 100% 0, 0 0, 100% 100%)",

              marginTop: "1px",
              zIndex: 1,
            }}
          >
            <div
              style={{
                marginLeft: "28px",
                marginTop: "5px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              무료
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default FreeBadge
