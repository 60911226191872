import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const GETBANNER = "store/module/bannerReducer/GETBANNER"
const SLIDERESET = "store/module/bannerReducer/SLIDERESET"
const DISPLAYRESET = "store/module/bannerReducer/DISPLAYRESET"
const BANDRESET = "store/module/bannerReducer/BANDRESET"
const BANDTOGGLE = "store/module/bannerReducer/BANDTOGGLE"
import { banner } from "utils/data"
export const getBanner = createAction(GETBANNER)
export const resetSlide = createAction(SLIDERESET)
export const resetDisplay = createAction(DISPLAYRESET)
export const resetBand = createAction(BANDRESET)
export const toggleband = createAction(BANDTOGGLE)
const { position, kind } = banner

export const getBannerAsync = key => async dispatch => {
  //month, notice 등 입력
  const result = await api.getBannerDetail(position.get(key))
  console.log(result)
  if (!result) return
  dispatch(getBanner(result))
}

const initialState = {
  slide: [],
  display: {},
  band: {},
  bandToggle: true,
}
//slide 제외하고 첫번째 요소만

const bannerReducer = handleActions(
  {
    [GETBANNER]: (state, { payload }) => {
      return produce(state, draft => {
        payload.forEach(({ kind: key }, i) => {
          switch (key) {
            case kind.get("slide"):
              draft.slide[i] = payload[i]
              break
            case kind.get("display"):
              draft.display = payload[i]
              break
            case kind.get("band"):
              draft.band = payload[i]
              break
          }
        })
      })
    },
    [SLIDERESET]: (state, action) =>
      produce(state, draft => {
        draft.slide = []
      }),
    [DISPLAYRESET]: (state, action) =>
      produce(state, draft => {
        draft.display = {}
      }),
    [BANDRESET]: (state, action) =>
      produce(state, draft => {
        draft.band = {}
      }),
    [BANDTOGGLE]: (state, action) =>
      produce(state, draft => {
        draft.bandToggle = !state.bandToggle
      }),
  },
  initialState
)

export default bannerReducer
