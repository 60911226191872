import { createAction, handleActions } from "redux-actions"
import { reactLocalStorage } from "reactjs-localstorage"
import produce from "immer"
import api from "apis"

const SET_PRODUCT_LIST = "store/module/payInfoReducer/SET_PRODUCT_LIST"
const SELECT_MAGAZINE = "store/module/payInfoReducer/SELECT_MAGAZINE"
const SELECT_MONTH = "store/module/payInfoReducer/SELECT_PLAN"
const GET_MAGAZINE_INFO = "store/module/payInfoReducer/GET_MAGAZINE_INFO"
const SELECT_COUPON = "store/module/payInfoReducer/SELECT_COUPON"
const SET_PAY_METHOD = "store/module/payInfoReducer/SET_PAY_METHOD"
const RESET = "store/module/payInfoReducer/RESET"
const COUPONRESET = "store/module/payInfoReducer/COUPONRESET"

export const setProductList = createAction(SET_PRODUCT_LIST)
export const selectMegazine = createAction(SELECT_MAGAZINE)
export const setMonth = createAction(SELECT_MONTH)
export const getMagazineInfo = createAction(GET_MAGAZINE_INFO)
export const setCoupon = createAction(SELECT_COUPON)
export const setPayMethod = createAction(SET_PAY_METHOD)
export const setPayReset = createAction(RESET)
export const setCouponReset = createAction(COUPONRESET)

export const setProductListAsync = () => async dispatch => {
  const shopClass = await api.getShopDetail()

  if (shopClass) {
    dispatch(setProductList(new Map(shopClass.shopList)))
    dispatch(
      getMagazineInfo({
        intro: shopClass.magazine_intro,
        title: shopClass.magazine_title,
        uuid: shopClass.thumb_magazine_uuid,
      })
    )
  }
}

// const pad = price => (price === 0 ? "00,000" : price.toLocaleString())
const initialState = {
  product: {
    list: new Map([]),
    clicked: {
      magazine: null,
      month: null, //
    },
    magazineInfo: {
      intro: "",
      title: "",
      uuid: "",
    },
    coupon: {
      day: 0,
      discount: 0,
      discountRate: 0,
      number: 0,
      title: "",
      isTrial: null,
    },
    payMethod: null,
  },
}

const payInfoReducer = handleActions(
  {
    [SET_PRODUCT_LIST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.list = payload
      })
    },
    [SELECT_MAGAZINE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.clicked.magazine = payload
      })
    },
    [SELECT_MONTH]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.clicked.month = payload
      })
    },
    [GET_MAGAZINE_INFO]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.magazineInfo = payload
      })
    },
    [SELECT_COUPON]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.coupon = payload
      })
    },
    [SET_PAY_METHOD]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product.payMethod = payload
      })
    },
    [RESET]: (state, { payload }) => {
      return produce(state, draft => {
        draft.product = initialState.product
      })
    },
    [COUPONRESET]: (state, { payload }) => {
      console.log(initialState)
      return produce(state, draft => {
        draft.product.coupon = initialState.product.coupon
      })
    },
  },
  initialState
)

export default payInfoReducer
