import dayjs from "dayjs"
import React, { Fragment } from "react"
import { onFavor, offFavor, smalloffFavor, smallonFavor } from "images"
import api from "apis"
import cookie from "react-cookies"

export const getDateYMD = (date, format) => {
  const time = dayjs.unix(date).locale("ko").format(format)
  // return time !== "Invalid Date"
  //   ? time
  //   : ` ${new Date().getFullYear()}년
  // ${new Date().getMonth() + 1}월 ${new Date().getDate()}일`
  return time !== "Invalid Date" ? time : dayjs().format("YYYY년 MM월 DD일")
}
//
export const getThumb = uuid => {
  return process.env.IMG + uuid + `?key=${dayjs()}`
}

export const getRawThumb = uuid => {
  return process.env.RAW + uuid + `?key=${dayjs()}`
}

export const textEdit = params =>
  params?.split(`\n`).map((v, i) => (
    <Fragment key={i}>
      {v}
      <br />
    </Fragment>
  ))

export const titleEdit = params =>
  params?.includes("[") ? (
    <>
      <div style={{ fontSize: "28px", display: "inline" }}>
        {params?.slice(0, params?.lastIndexOf("["))}
      </div>
      <div style={{ fontSize: "17px", display: "inline", fontWeight: "500" }}>
        {params?.slice(params?.lastIndexOf("["))}
      </div>
    </>
  ) : (
    <>{params}</>
  )

// export const slideEdit = (array, length) => {
//   const slideArr = []
//   while (array.length) slideArr.push(array.splice(0, length))

//   return slideArr
// }

export const arrayEdit = (array, con) =>
  array
    .filter(
      element => element !== "" && element !== undefined && element !== null
    )
    .join(con ? con : "#")

export const onLike = async ({ target }, no, keyword, mini) => {
  const img = {
    on: mini ? smallonFavor : onFavor,
    off: mini ? smalloffFavor : offFavor,
  }

  const isfavor = target.src === img.off

  const result = await api.onLike(keyword, {
    body: {
      [keyword + "_number"]: no,
      [keyword + "_like"]: isfavor,
    },
  })
  if (result) target.src = isfavor ? img.on : img.off
  console.log(target.src)
}

export const customCount = count =>
  count >= 10 ? count.toLocaleString() : `0${count}`

export const getEpisode = (total, currentPage, size, i) => {
  return total - (currentPage - 1) * size - i
}

export const onShare = async (setmodalOpen, setModalMain) => {
  await navigator.clipboard.writeText(globalThis.location.href)
  setmodalOpen(true)
  setModalMain(
    <div
      style={{
        fontSize: "16px",
        width: "350px",
        height: "120px",
        textAlign: "center",
        borderRadius: "5px",
        padding: "20px 0 20px 0",
      }}
    >
      <div>링크가 복사되었습니다.</div>

      <div
        style={{
          backgroundColor: "#0B4247",
          borderRadius: "10px",
          color: "#fff",
          width: "142px",
          margin: "30px auto 0 auto",
          fontSize: "14px",
          height: "25px",
          cursor: "pointer",
        }}
        onClick={() => {
          setmodalOpen(false)
        }}
      >
        확인
      </div>
    </div>
  )
}

export const getTermContents = TermContent => {
  let text = ""
  if (TermContent) TermContent.forEach(v => (text += v.bodyText))

  return text
}

export const setSort = (object, keyName) =>
  object.sort((a, b) =>
    a[keyName] < b[keyName] ? -1 : a[keyName] > b[keyName] ? 1 : 0
  )

export const download = (url, name) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
    })
    .catch(console.error)
}

export const getRemoveUI = async (
  setModalMain,
  setmodalOpen,
  mainText,
  onConfirm
) => {
  const close = () => {
    setmodalOpen(false)
  }

  setModalMain(
    <div className="modalText">
      {mainText}
      <p
        style={{
          display: "flex",
          width: "100%",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <div
          onClick={close}
          style={{
            width: "90px",
            height: "36px",
            border: "1px solid #0B4247",
            lineHeight: "36px",
            borderRadius: "20px",
            cursor: "pointer",
          }}
        >
          취소
        </div>
        <div
          onClick={() => {
            close()
            onConfirm()
          }}
          style={{
            width: "90px",
            height: "36px",
            lineHeight: "36px",
            borderRadius: "20px",
            backgroundColor: "#0B4247",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          삭제
        </div>
      </p>
    </div>
  )
  setmodalOpen(true)
}

// export const onErrorComeIn = async (setmodalOpen, setModalMain, rank) => {
//   await navigator.clipboard.writeText(globalThis.location.href)
//   setmodalOpen(true)
//   setModalMain(
//     <div
//       style={{
//         fontSize: "16px",
//         width: "350px",
//         height: "120px",
//         textAlign: "center",
//         borderRadius: "5px",
//         padding: "20px 0 20px 0",
//       }}
//     >
//       <div>
//         {rank == 0 ? <>로그인 후 이용해주세요</> : <>구독 후 이용해주세요.</>}
//       </div>

//       <div
//         style={{
//           backgroundColor: "#0B4247",
//           borderRadius: "10px",
//           color: "#fff",
//           width: "142px",
//           margin: "30px auto 0 auto",
//           fontSize: "14px",
//           height: "25px",
//         }}
//         onClick={() => {
//           setmodalOpen(false)
//         }}
//       >
//         확인
//       </div>
//     </div>
//   )
// }

export const getFormData = data => {
  const formdata = new FormData()
  for (const key in data) {
    formdata.append(`${key}`, data[key])
  }

  return formdata
}

export const getJson = key => {
  try {
    return JSON.parse(cookie.load(`${key}`, { path: "/" }))
  } catch (error) {
    return
  }
}

export const getRank = () => {
  return getJson("access_token")?.rank ?? 0
}

export const getIsApp = () => {
  return globalThis.navigator?.userAgent.toLowerCase().match(/isapp/i) === null
    ? false
    : true
} // 앱이면 true / 아니면 false

export const getIsMobile = () => {
  return globalThis.navigator?.userAgent
    .toLowerCase()
    .match(/Android|iPhone|iPad|iPod/i) === null
    ? false
    : true
} // 모바일이면 true / 아니면 false

export const getIsScb = subscribe => {
  if (!subscribe) return false
  const is_invalid_time = dayjs().unix() < subscribe.subscribe_end //
  return is_invalid_time
}

import { reactLocalStorage } from "reactjs-localstorage"
export const logOut = () => {
  cookie.remove("access_token", {
    path: "/",
  })
  cookie.remove("profile", { path: "/" })
  reactLocalStorage.remove(process.env.SHOP)
}

import { setContextError } from "store/modules/errorReducer.js"
import configureStore from "store"
import { rank } from "utils/data"
import { navigate } from "gatsby"
export const permission = url => {
  if (getRank() === rank.NORMAL) {
    configureStore.dispatch(setContextError("subscription required"))
  } else {
    if (url) navigate(url)
  }
}

export const getExpire = (name = "expires", path = "/") => {
  const option = { path }
  const limit = getJson(name) ?? cookie.load(`${name}`, option)
  if (limit) option["expires"] = dayjs(limit).toDate()
  return option
}
