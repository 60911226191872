import {
  topic1,
  topic2,
  topic3,
  topic4,
  topic5,
  topic6,
  topic7,
  topic8,
  topic9,
  topic10,
  topic11,
  topic12,
  topic13,
  topic14,
  topic15,
  topic16,
  topic17,
  topic18,
  topic19,
  topic20,
  topic21,
  topic22,
  topic23,
  topic24,
  topic25,
} from "images"

//프로필 이미지
import {
  annHappy,
  annReading,
  annRobert,
  breadJang,
  businessmanFriendship,
  chocolateDelicious,
  fireJuliet,
  fireRomeo,
  firstTime31,
  flowerSoeun,
  fortuneTelling,
  fortuneTellingMan,
  gameAlice,
  gameRabbit,
  hatAgo,
  LandPilot,
  LastClassFranz,
  LastClassTeacher,
  LastClassWhisper,
  leeSangMainCharacter,
  LittlePrinceBusinessman,
  LittlePrinceKing,
  LittlePrinceRose,
  LittlePrinceScholar,
  LittlePrinceTame,
  Mikhail,
  mirrorNarcissus,
  moneyAnt,
  moneySong,
  OldmanSeaFishing,
  oliverPatting,
  OliverRun,
  peachNoteBoss,
  peachNotePeach,
  pocketHump,
  starNightSee,
  starPythagoras,
  startNightYoon,
  treeWhatis,
  wingBrave,
} from "images"

// 관심주제
export const topics = new Map([
  [
    1,
    {
      contents: "음식",
      img: topic1,
    },
  ],
  [
    2,
    {
      contents: "동물",
      img: topic2,
    },
  ],
  [
    3,
    {
      contents: "어휘",
      img: topic3,
    },
  ],
  [
    4,
    {
      contents: "감정",
      img: topic4,
    },
  ],
  [
    5,
    {
      contents: "대인관계",
      img: topic5,
    },
  ],
  [
    6,
    {
      contents: "사회적 매너",
      img: topic6,
    },
  ],
  [
    7,
    {
      contents: "과학",
      img: topic7,
    },
  ],
  [
    8,
    {
      contents: "생활수학",
      img: topic8,
    },
  ],
  [
    9,
    {
      contents: "역사",
      img: topic9,
    },
  ],
  [
    10,
    {
      contents: "환경",
      img: topic10,
    },
  ],
  [
    11,
    {
      contents: "인권",
      img: topic11,
    },
  ],
  [
    12,
    {
      contents: "젠더",
      img: topic12,
    },
  ],
  [
    13,
    {
      contents: "트렌드",
      img: topic13,
    },
  ],
  [
    14,
    {
      contents: "건강",
      img: topic14,
    },
  ],
  [
    15,
    {
      contents: "여가 · 문화",
      img: topic15,
    },
  ],
  [
    16,
    {
      contents: "자기결정",
      img: topic16,
    },
  ],
  [
    17,
    {
      contents: "자기관리",
      img: topic17,
    },
  ],
  [
    18,
    {
      contents: "직업생활",
      img: topic18,
    },
  ],
  [
    19,
    {
      contents: "지역사회 이용",
      img: topic19,
    },
  ],
  [
    20,
    {
      contents: "토론",
      img: topic20,
    },
  ],
  [
    21,
    {
      contents: "문제해결",
      img: topic21,
    },
  ],
  [
    22,
    {
      contents: "현장학습",
      img: topic22,
    },
  ],
  [
    23,
    {
      contents: "논리적 사고",
      img: topic23,
    },
  ],
  [
    24,
    {
      contents: "미디어 활용",
      img: topic24,
    },
  ],
  [
    25,
    {
      contents: "그림으로 보기",
      img: topic25,
    },
  ],
])

// speech

export const speech = {
  1: "명사",
  2: "동사",
  3: "형용사",
  4: "부사",
}
export const orderTable = new Map([
  [1, "최근등록순"],
  [2, "오래된순"],
  [3, "조회수순"],
  [4, "찜하기순"],
])

//category

export const storyCategory = {
  main: new Map([
    [100, "소통"],
    [200, "지식"],
    [300, "뉴스"],
    [400, "문학"],
    [500, "자립"],
  ]),
  sub: new Map([
    [101, "감정이해"],
    [102, "사회성"],
    [103, "언어"],
    [201, "역사"],
    [202, "과학"],
    [203, "사회"],
    [301, "사회"],
    [302, "국제"],
    [303, "문화"],
    [401, "시 · 에세이"],
    [402, "소설"],
    [403, "그림책"],
    [501, "여가"],
    [502, "생활정보"],
    [503, "진로"],
  ]),
}

export const profiles = new Map([
  [1, annHappy],
  [2, annReading],
  [3, annRobert],
  [4, breadJang],
  [5, businessmanFriendship],
  [6, chocolateDelicious],
  [7, fireJuliet],
  [8, fireRomeo],
  [9, firstTime31],
  [10, flowerSoeun],
  [11, fortuneTelling],
  [12, fortuneTellingMan],
  [13, gameAlice],
  [14, gameRabbit],
  [15, hatAgo],
  [16, LastClassFranz],
  [17, LastClassTeacher],
  [18, LastClassWhisper],
  [19, leeSangMainCharacter],
  [20, LittlePrinceBusinessman],
  [21, LittlePrinceKing],
  [22, LittlePrinceRose],
  [23, LittlePrinceScholar],
  [24, LittlePrinceTame],
  [25, Mikhail],
  [26, mirrorNarcissus],
  [27, moneyAnt],
  [28, moneySong],
  [29, OldmanSeaFishing],
  [30, oliverPatting],
  [31, OliverRun],
  [32, peachNoteBoss],
  [33, peachNotePeach],
  [34, pocketHump],
  [35, starNightSee],
  [36, starPythagoras],
  [37, startNightYoon],
  [38, treeWhatis],
  [39, wingBrave],
  [40, LandPilot],
])

export const table = new Map([
  [1, "storyRaw"],
  [2, "illustRaw"],
  [3, "voiceRaw"],
  [4, "story"],
  [5, "illust"],
  [6, "video"],
  [7, "exhibit"],
  [8, "eduNanum"],
  [9, "eduTalk"],
  [10, "monthBookCase"],
  [11, "room"],
])

//약관
export const term = new Map([
  [1, "이용약관"],
  [2, "서비스 이용약관"],
  [3, "개인정보처리방침"],
  [4, "운영정책"],
  [5, "전자금융거래약관"],
  [6, "청소년보호정책"],
  [7, "개인정보 수집 이용 동의"],
  [8, "마케팅 활용 및 광고성 정보 수신 동의"],
  [9, "개인정보 제 3자 제공 동의"],
])
//소개

export const intro = new Map([[1, "About us"]])

//배너
export const banner = {
  position: new Map([
    ["front", 1],
    ["news", 2],
    ["month", 3],
    ["notice", 4],
    ["foryou", 5],
  ]),
  kind: new Map([
    ["slide", 1],
    ["display", 2],
    ["band", 3],
  ]),
}

//faq
export const faq = new Map([
  [1, "시스템"],
  [2, "회원정보"],
  [3, "수업나눔"],
  [4, "구독"],
  [5, "기타"],
])

//searchTag (검색창 태그 선택하는 목록)
export const searchTag = new Map([
  ["제목", 1],
  ["태그", 2],
  ["내용", 3],
])

export const Tag = new Map([
  // [1001, "전체"],
  [3001, "태그"],
  [2001, "제목"],
])

//headerMenu ( full - 정회원, associate - 준회원, none - 비회원)
export const rank = {
  NONE: 0,
  NORMAL: 1,
  REGULAR: 2,
}

export const alias = {
  MONTH: "월간 피치서가",
  NOTICE: "소식",
  ACCOUNT: "계정",
  SIGNUP: "회원가입",
  SUB: "정기구독",
  TOPIC: "관심주제",
  CATEGORY: "카테고리",
  EXH: "기획전",
  LESSON: "수업나눔",
  ROOM: "교실이야기",
  MEDIA: "영상",
  CARD: "오늘의 단어",
  ALL: "전체",
  STORY: "스토리",
  ALARM: "알람",
}
export const menu = new Map([
  ["all", alias.ALL],
  ["story", alias.STORY],
  ["edunanum", alias.LESSON],
  ["illust", alias.CARD],
  ["exhibit", alias.EXH],
  ["edutalk", alias.ROOM],
  ["video", alias.MEDIA],
])

export const headerMenu = new Map([
  [
    rank.NONE,
    [alias.SIGNUP, alias.SUB, alias.MONTH, alias.NOTICE, alias.ACCOUNT],
  ],
  [
    rank.NORMAL,
    [alias.SUB, alias.MONTH, alias.NOTICE, alias.ALARM, alias.ACCOUNT],
  ],
  [rank.REGULAR, [alias.MONTH, alias.NOTICE, alias.ALARM, alias.ACCOUNT]],
])

//linkManage
export const linkManage = new Map([
  [alias.MONTH, "/monthlyPeach/list/"],
  [alias.NOTICE, "/notice/list"],
  [alias.ACCOUNT, ""],
  [alias.SIGNUP, "/member/signUp/terms"],
  [alias.SUB, "/subscription"],
  [alias.TOPIC, ""],
  [alias.CATEGORY, ""],
  [alias.EXH, "/news/list"],
  [alias.LESSON, "/class/lesson/list"],
  [alias.ROOM, "/class/room/list"],
  [alias.CARD, "/visible/card/list"],
  [alias.MEDIA, "/visible/media/list/"],
])

export const lessonMenu = new Map([
  [
    "course",
    {
      title: "대상",
      contents: new Map([
        ["elem_co", "초등"],
        ["midd_co", "중등"],
        ["high_co", "고등"],
        ["adul_co", "성인"],
      ]),
    },
  ],
  [
    "curriculum",
    {
      title: "과목",
      contents: new Map([
        ["nation_cu", "국어"],
        ["mather_cu", "수학"],
        ["social_cu", "사회"],
        ["scienc_cu", "과학"],
        ["practi_cu", "직업"],
        ["artist_cu", "예체능"],
        ["etc_cu", "기타"],
      ]),
    },
  ],
  [
    "kind",

    {
      title: "종류",
      contents: new Map([
        ["actpaper_kn", "활동지"],
        ["powpoint_kn", "PPT"],
        ["lessonre_kn", "수업후기"],
        ["etc_kn", "기타"],
      ]),
    },
  ],
])

export const getKey = (map, value) =>
  Object.keys(map).find(key => map[key] === value)

export const wholeTable = new Map([
  [1, "storyRaw"],
  [2, "illustRaw"],
  [3, "voiceRaw"],
  [4, "story"],
  [5, "illust"],
  [6, "video"],
  [7, "exhibit"],
  [8, "eduNanum"],
  [9, "eduTalk"],
  [10, "eduTalkWriter"],
  [11, "bookStory"],
  [12, "bookPage"],
  [13, "bookContent"],
  [14, "recent"],
  [15, "news"],
  [16, "faq"],
  [17, "question"],
  [18, "answer"],
  [19, "attach"],
])

export const declareState = new Map([
  [1, "혐오, 비하 발언 또는 상징하는 내용"],
  [2, "폭력, 협박으로 신체 또는 재산을 위협하는 내용"],
  [3, "음란행위, 성적 불쾌감을 주는 내용"],
  [4, "거짓 정보의 내용"],
  [5, "광고, 홍보의 상업적인 내용"],
])

// 등급
// Rank: { none: 0, associate: 1, regular: 2 },

// 관리자 등급
// Admin: { none: 0, associate: 1, super: 2 },

export const errDesc = new Map([
  [
    "login required",
    {
      mainMsg: "로그인 후 이용해주세요.",
      btnMsg: "로그인",
      link: "/login",
    },
  ],
  [
    "profile required",
    {
      mainMsg: "프로필을 생성 후 이용해주세요",
      btnMsg: "프로필 생성",
      link: "/member/profile/registerProfile",
    },
  ],
  [
    "subscription required",
    {
      mainMsg: "피치서가 콘텐츠를 무제한으로 이용하세요.",
      btnMsg: "구독하러 가기",
      link: "/subscription",
    },
  ],
  [
    "admin required",
    {
      mainMsg: "관리자 권한이 필요한 기능입니다",
      btnMsg: "확인",
      link: "",
    },
  ],
])

export const subscribeType = new Map([
  [1, "혐오, 비하 발언 또는 상징하는 내용"],
  [2, "폭력, 협박으로 신체 또는 재산을 위협하는 내용"],
  [3, "음란행위, 성적 불쾌감을 주는 내용"],
  [4, "거짓 정보의 내용"],
  [5, "광고, 홍보의 상업적인 내용"],
])

import paymentSingle from "images/paymentSingle.png"
import paymentIns from "images/paymentIns.png"
import paymentSch from "images/paymentSch.png"
export const scbType = {
  scb: new Map([
    [1, { id: "sin", name: "개인 회원", img: paymentSingle }],
    [2, { id: "sch", name: "학교 회원", img: paymentSch }],
    [3, { id: "ins", name: "기관 회원", img: paymentIns }],
  ]),
  teacher: new Map([
    [1, "특수학급 교사"],
    [2, "특수학교 교사"],
    [3, "통합학급 교사"],
    [4, "특수교육지원센터 교사"],
  ]),
}

export const scbMemberType = new Map([
  [0, "none"],
  [1, "single"],
  [2, "group"],
  [3, "groupMember"],
])

import { account_transfer, charge_card, phone } from "images"
export const payMethodTable = new Map([
  [
    "card",
    {
      key: 1,
      name: "신용카드",
      img: charge_card,
    },
  ],
  [
    "trans",
    {
      key: 2,
      name: "계좌이체",
      img: account_transfer,
    },
  ],
  [
    "phone",
    {
      key: 3,
      name: "휴대폰",
      img: phone,
    },
  ],
  [1, "신용카드"],
  [2, "계좌이체"],
  [3, "휴대폰"],
  [99, "피치서가 이용쿠폰"],
])

// export const payType = new Map([
//   ["reg", 1],
//   ["gen", 2],
// ])

export const billing = {
  BEGIN: 1,
  END_SUCCESS: 2,
  END_FAIL: 3,
}

export const MINE = {
  STORY: {
    recent: "최근 본 스토리",
    like: "내가 찜한 스토리",
    exhLike: "내가 찜한 기획전",
  },
  CLASS: {
    recent: "최근 본 수업나눔",
    like: "내가 찜한 수업나눔",
    writed: "나의 수업나눔",
    roomLike: "내가 찜한 교실이야기",
  },
  VIS: {
    likeCard: "내가 찜한 그림단어",
    likeMedia: "내가 찜한 영상",
  },
}

export const HIDDENPAGE = {
  initial: "initial",
  server_error: "server_error",
  user_error: "user_error",
}

export const couponState = {
  REG: 1,
  ONLINE6: 2,
  ONLINE12: 4,
  MAGAZINE6: 3,
  MAGAZINE12: 5,
}

// 정기구독(1) , 온라인 6개월(2) , 온라인 매거진 6개월(3), 온라인 12개월(4) , 온라인 매거진 12개월(5)
