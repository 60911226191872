import { getThumb, download as utildownload } from "utils"
export default class FileMap {
  constructor(file) {
    this.db_uuid = file.data_uuid
    this.uuid = getThumb(file.data_uuid)
    this.name = file.data_name
    this.file_no = file.seq_no
    this.store_no = file.store_no
  }

  download() {
    utildownload(this.uuid, this.name)
  }

  async setFile() {
    const res = await fetch(this.uuid)
    const blob = await res.blob()
    const file = new File([blob], this.name, { type: blob.type })
    file.uuid = this.db_uuid
    file.file_no = this.file_no

    return file
  }
}
