import React, { Fragment, useState, useEffect } from "react"
import { onFavor, offFavor, smalloffFavor, smallonFavor } from "images"
import api from "apis"
import address from "apis/address"
import _ from "lodash"
const Like = props => {
  const { off, on, keyword, style, postNumber } = props

  // 예상 props
  // 1. 현재 페이지의 detail 정보
  // 2. 현재 detail로 호출하는 좋아요 숫자
  const [isLike, setIsLike] = useState(props.isLike)
  const [count, setCount] = useState(props.count)

  useEffect(() => {
    setIsLike(!Boolean(props.isLike))
    setCount(Number(props.count))
  }, [props])

  const onLike = _.debounce(async () => {
    const result = await api.onLike(`${keyword}`, {
      body: {
        [`${keyword}_number`]: postNumber,
        [`${keyword}_like`]: isLike,
      },
    })

    if (result) {
      setCount(count => (isLike ? (count += 1) : (count -= 1)))
      setIsLike(!isLike)
    }
  }, 300)

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      onClick={e => {
        // 상위 이벤트 1차적으로 중지
        e.stopPropagation()
        onLike()
      }}
    >
      <img
        src={!isLike ? on : off}
        name={"like"}
        style={{ ...style?.img, cursor: "pointer" }}
      />
      {!isNaN(count) ? <div style={style?.count}>{count}</div> : null}
    </div>
  )
}

export default Like
