import React, { Fragment, useState, useEffect } from "react"
import * as styles from "./backSpace.module.css"
import btn from "images/backButton.png"
import { getIsApp } from "utils"
const BackSpace = props => {
  const [flag, setFlag] = useState(true)

  useEffect(() => {
    setFlag(!getIsApp() || props.location.pathname === "/peachPage")
  }, [props])

  if (flag) return <Fragment key={"none"}></Fragment>

  return (
    <div
      key={"back"}
      className={styles.background}
      onClick={() => history.back()}
    >
      <img className={styles.arrow} src={btn} alt="뒤로가기" />
    </div>
  )
}

export default BackSpace
