import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import { AlarmMap } from "class"
import api from "apis"

const GETALARM = "store/module/alarmReducer/GETARLARM"
const SETARLARMLIST = "store/module/alarmReducer/SETARLARMLIST"

const getAlarmList = createAction(GETALARM)
export const setAlarmList = createAction(SETARLARMLIST)

export const getAlarmListAsync = () => async dispatch => {
  const result = await api.getAlarm()
  console.log(808011, result)
  if (!result?.length) return
  dispatch(getAlarmList(result))
}

const initialState = {
  alarm: {
    list: [],
  },
}

const alarmReducer = handleActions(
  {
    [GETALARM]: (state, { payload }) => {
      return produce(state, draft => {
        draft.alarm.list = payload
      })
    },
    [SETARLARMLIST]: (state, { payload }) => {
      console.log(
        7373,
        state.alarm.list.filter(v => v.number !== payload)
      )
      return produce(state, draft => {
        draft.alarm.list = state.alarm.list.filter(v => v.number !== payload)
      })
    },
  },
  initialState
)

export default alarmReducer
