// process.env.IDC

// const a = {
//   pay_method: payMethod,
//   name: "",
//   amount: getPrice(),
//   buyer_email: "",
//   buyer_name: "",
//   buyer_tel: "",
// }
import { navigate } from "gatsby"
import { payMethodTable, billing } from "utils/data.js"
import api from "apis"
import { getIsApp, getJson } from "utils"
export default class Payment {
  constructor(isReg) {
    this.idc = process.env.IDC
    this.pay_method = ""
    this.merchant_uid = ""
    this.name = ""
    this.amount = 0
    this.buyer_email = ""
    this.buyer_name = ""
    this.buyer_tel = ""
    this.imp = globalThis.IMP
    this.pg = ""
    //정기결제나 일반결제에따라 빈 값일수있음
    this.customer_uid = ""
    this.m_redirect_url = ""
    this.buyer_addr = ""
    this.buyer_postcode = ""
    //
    this.goods_no = ""
    this.payment_no = 0
    //
    this.isReg = isReg
    this.success = null
    this.fail = msg => {
      alert(msg)
    }
    this.coupon_no = 0
  }
  set coupon_no(value) {
    this._coupon_no = value
  }
  get coupon_no() {
    return this._coupon_no
  }
  set payment_no(value) {
    this._payment_no = value
  }
  get payment_no() {
    return this._payment_no
  }
  get idc() {
    return this._idc
  }
  get pay_method() {
    return this._pay_method
  }
  get merchant_uid() {
    return this._merchant_uid
  }
  get name() {
    return this._name
  }
  get amount() {
    return this._amount
  }
  get buyer_email() {
    return this._buyer_email
  }
  get buyer_name() {
    return this._buyer_name
  }
  get buyer_tel() {
    return this._buyer_tel
  }

  get imp() {
    return this._imp
  }
  get pg() {
    return this._cpg
  }

  get fail() {
    return this._fail
  }

  set fail(value) {
    this._fail = value
  }
  get goods_no() {
    return this._goods_no
  }
  set goods_no(value) {
    this._goods_no = value
  }

  set pg(value) {
    this._pg = value
  }
  set imp(value) {
    this._imp = value
  }

  set idc(value) {
    this._idc = value
  }
  set pay_method(value) {
    this._pay_method = value
  }
  set merchant_uid(value) {
    this._merchant_uid = value
  }
  set name(value) {
    this._name = value
  }
  set amount(value) {
    this._amount = value
  }
  set buyer_email(value) {
    this._buyer_email = value
  }
  set buyer_name(value) {
    this._buyer_name = value
  }
  set buyer_tel(value) {
    this._buyer_tel = value
  }
  //
  set success(value) {
    this._success = value
  }
  get success() {
    return this._success
  }
  //

  get m_redirect_url() {
    return this._m_redirect_url
  }
  get customer_uid() {
    return this._customer_uid
  }
  set customer_uid(value) {
    this._customer_uid = value
  }
  set m_redirect_url(value) {
    this._m_redirect_url = value
  }
  get buyer_addr() {
    return this._buyer_addr
  }
  set buyer_addr(value) {
    this._buyer_addr = value
  }
  get buyer_postcode() {
    return this._buyer_postcode
  }
  set buyer_postcode(value) {
    this._buyer_postcode = value
  }

  async init(blist = []) {
    this.pg = ""
    this.pay_method = ""
    this.merchant_uid = ""
    this.name = ""
    this.amount = 0
    this.buyer_email = ""
    this.buyer_name = ""
    this.buyer_tel = ""
    this.customer_uid = ""
    this.buyer_addr = ""
    this.buyer_postcode = ""
    this.goods_no = ""
    this.m_redirect_url = ""
    this.payment_no = 0
    this.imp_uid = ""
    this.error_msg = ""
    this.imp.init(this.idc)
    //

    const beginList = blist.filter(v => v.billing_status === billing.BEGIN)
    await Promise.all(
      beginList.map(async ({ billing_no }) => {
        await api.payCancel({
          body: {
            billing_number: billing_no,
          },
        })
      })
    )
  }

  _info() {
    const info = {
      pg: this.pg,
      pay_method: this.pay_method,
      merchant_uid: this.merchant_uid,
      name: this.name,
      amount: this.amount,
      buyer_email: this.buyer_email,
      buyer_name: this.buyer_name,
      buyer_tel: this.buyer_tel,
    }
    return info
  }

  _diff() {
    const diff = {}

    if (this.isReg) {
      diff["customer_uid"] = this.customer_uid
      diff["m_redirect_url"] = this.m_redirect_url
      diff["pg"] = process.env.REG_MID
    } else {
      diff["buyer_addr"] = this.buyer_addr
      diff["buyer_postcode"] = this.buyer_postcode
      diff["pg"] = process.env.GEN_MID
    }

    return diff
  }

  async setting() {
    //
    const body = {
      billing_goods: this.goods_no,
      billing_amount: this.amount,
      billing_method: payMethodTable.get(this.pay_method).key,
    }
    if (this.coupon_no !== 0) body["billing_coupon"] = this.coupon_no

    const result = await api.payBegin({
      body,
    })

    if (result) {
      const { customer_uuid, billing_no, merchant_uuid } = result

      this.payment_no = billing_no
      this.merchant_uid = merchant_uuid // 내부서버에서 생성하는 고유 주문 uuid
      if (customer_uuid) this.customer_uid = customer_uuid
    }
  }

  async run() {
    this.imp.request_pay(
      {
        ...this._info(),
        ...this._diff(),
      },
      async rsp => {
        if (rsp.success) {
          // 아임포트에서 결제성공한것 내부서버에 전송

          const body = {
            billing_number: `${this.payment_no}`,
            billing_merchant: this.merchant_uid,
          }
          await api.insertLog({
            body: {
              client_code: 1,
              client_body: {
                msg: "아임포트 결제성공",
                profile: getJson("profile"),
              },
            },
          })

          if (this.isReg) {
            body["billing_customer"] = this.customer_uid
          } else {
            body["billing_imp"] = rsp.imp_uid
          }

          const isEnd = await api.payEnd({
            body,
          })

          // 내부서버 전송 결과에 따라 외부에서 ui실행

          if (isEnd) {
            //결제성공시 이동하는 페이지
            alert("결제가 완료되었습니다.")
            navigate(getIsApp() ? "/front" : "/subscription/success/")
          } else {
            // 내부 서버에서 결제 실패
            //문의하기 등으로 페이지이동 필요
            alert("정상적인 결제가 아닙니다 관리자에게 문의해주세요.")
            navigate("/cs/contact/list/")
          }
        } else {
          //아임포트에서 오류발생함
          // 내부 서버에 전송

          await api.payCancel({
            body: {
              billing_number: `${this.payment_no}`,
            },
          })
          //실패시 ui 실행
          this.fail(rsp.error_msg)
        }
      }
    )
  }
}
