import { createStore, applyMiddleware, compose } from "redux"
import logger from "redux-logger"
import { composeWithDevTools } from "redux-devtools-extension"

import thunk from "redux-thunk"
import modules from "./modules"

const configureStore = () => {
  if (process.env.NODE_ENV === "production")
    return createStore(modules, applyMiddleware(thunk))
  else
    return createStore(
      modules,
      composeWithDevTools(applyMiddleware(thunk, logger))
    )
}

export default configureStore
