import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
const SETSEARCHSTATE = "store/module/searchReducer/SETSEARCHSTATE"
const GETSTORY = "store/module/searchReducer/GETSTORY"
const GETEDUNANUM = "store/module/searchReducer/GETEDUNANUM"
const GETILLUST = "store/module/searchReducer/GETILLUST"
const GETEXHIBIT = "store/module/searchReducer/GETEXHIBIT"
const GETEDUTALK = "store/module/searchReducer/GETEDUTALK"
const GETVIEDO = "store/module/searchReducer/GETVIEDO"
const RESET = "store/module/searchReducer/RESET"
const TOGGLE = "store/module/searchReducer/TOGGLE"
export const setSearchState = createAction(SETSEARCHSTATE)
export const getStory = createAction(GETSTORY)
export const getEdunanum = createAction(GETEDUNANUM)
export const getIllust = createAction(GETILLUST)
export const getExhibit = createAction(GETEXHIBIT)
export const getEdutalk = createAction(GETEDUTALK)
export const getVideo = createAction(GETVIEDO)
export const resetOption = createAction(RESET)
export const searchToggle = createAction(TOGGLE)

const actions = {
  story: getStory,
  edunanum: getEdunanum,
  illust: getIllust,
  exhibit: getExhibit,
  edutalk: getEdutalk,
  video: getVideo,
}

export const getSearchListAsync =
  (page, take, order, type, tagNumber, keyword, checkedList) =>
  async dispatch => {
    const result =
      (await api.getSearchList(
        page,
        take,
        order,
        type,
        tagNumber,
        keyword,
        checkedList
      )) ?? [] // type에따라 스위치문

    console.log({ page, take, order, type, tagNumber, keyword })

    dispatch(actions[type](result))
  }

const initialState = {
  option: {
    keyword: "",
    tag: 1001,
    pageMove: false,
    toggle: false,
  },
  story: {
    storyList: [],
    storyTotal: 0,
  },
  edunanum: {
    edunanumList: [],
    edunanumTotal: 0,
  },
  illust: {
    illustList: [],
    illustTotal: 0,
  },
  exhibit: {
    exhibitList: [],
    exhibitTotal: 0,
  },
  edutalk: {
    edutalkList: [],
    edutalkTotal: 0,
  },
  video: {
    videoList: [],
    videoTotal: 0,
  },
}

const searchReducer = handleActions(
  {
    [SETSEARCHSTATE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.option = {
          ...state.option,
          ...payload,
        }
      })
    },
    [GETSTORY]: (state, { payload }) => {
      return produce(state, draft => {
        draft.story.storyList = payload.pageList
        draft.story.storyTotal = payload.total
      })
    },
    [GETEDUNANUM]: (state, { payload }) => {
      return produce(state, draft => {
        draft.edunanum.edunanumList = payload.pageList
        draft.edunanum.edunanumTotal = payload.total
      })
    },
    [GETILLUST]: (state, { payload }) => {
      return produce(state, draft => {
        draft.illust.illustList = payload.pageList
        draft.illust.illustTotal = payload.total
      })
    },
    [GETEXHIBIT]: (state, { payload }) => {
      return produce(state, draft => {
        draft.exhibit.exhibitList = payload.pageList
        draft.exhibit.exhibitTotal = payload.total
      })
    },
    [GETEDUTALK]: (state, { payload }) => {
      return produce(state, draft => {
        draft.edutalk.edutalkList = payload.pageList
        draft.edutalk.edutalkTotal = payload.total
      })
    },
    [GETVIEDO]: (state, { payload }) => {
      return produce(state, draft => {
        draft.video.videoList = payload.pageList
        draft.video.videoTotal = payload.total
      })
    },
    [RESET]: (state, { payload }) => {
      return produce(state, draft => {
        draft.option.keyword = ""
        draft.option.tag = 0
      })
    },
    [TOGGLE]: (state, { payload }) => {
      return produce(state, draft => {
        draft.option.toggle = !draft.option.toggle
      })
    },
  },
  initialState
)

export default searchReducer
