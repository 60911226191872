import api from "apis"
import { getJson, getThumb, getDateYMD } from "utils"
import { profiles, lessonMenu } from "utils/data.js"
import { FileMap } from "class"
export default class Lesson {
  constructor(db) {
    this.db = db
    this.nanum_no = db.nanum_no
    this.isComment = db.comment
    this.accStory = []
    this.loginProfile = null
    this.writerProfile = null
    this.isMine = false
    this.story = {}
    this.title = db?.title ?? ""
    this.registerTime = getDateYMD(db?.registerTime, "YYYY.MM.DD")
    this.hit = db?.hit ?? 0
    this.Like = db?.Like
    this.like = db?.like
    this.bodyText = db?.bodyText
    this.fileList = []
    this.course = []
    this.curriculum = []
    this.kind = []
    this.parse_course = []
    this.parse_curriculum = []
    this.parse_kind = []
    this.init()
  }

  async init() {
    this._getLoginProfile()
    this._getWriterProfile()
    await this._getAccStorys()
    this._getStroy()
    this._getFileList()
    this._getMenu(alias.cur)
    this._getMenu(alias.crs)
    this._getMenu(alias.kind)
    this._getParse(alias.cur)
    this._getParse(alias.crs)
    this._getParse(alias.kind)
    this._getIsMine()
    return this
  }

  async _getAccStorys() {
    this.accStory = await api.associatedStory(this.nanum_no, "nanum")
  }
  _getFileList() {
    const fileList = this.db?.Attach

    this.fileList = fileList.map(v => new FileMap(v))
  }

  _getIsMine() {
    if (this.loginProfile && this.writerProfile) {
      this.isMine = this.loginProfile.uuid === this.writerProfile.uuid
    }
  }

  _getLoginProfile() {
    const loginProfile = getJson("profile")

    this.loginProfile = {
      uuid: loginProfile?.uuid ?? "login_profile_uuid",
      name: loginProfile?.name ?? "",
    }
  }

  _getWriterProfile() {
    const writerProfile = this.db?.Profile

    this.writerProfile = {
      uuid: writerProfile?.uuid ?? "lesson_writer_uuid",
      name: writerProfile?.name ?? "",
      icon: profiles.get(writerProfile?.icon_no),
    }
  }

  _getStroy() {
    const story = this.db?.Story
    this.story.img = getThumb(story?.thumb_title_uuid)
    this.story.introText = story?.introText ?? ""
    this.story.number = story?.story_no ?? 0
  }

  _getMenu(alias) {
    const addPost = {}
    const contents = Array.from(lessonMenu.get(alias).contents)

    this[alias] = contents.reduce((prev, [key, value], i) => {
      addPost[key] = this.db[alias] & (1 << i)
      if (addPost[key]) prev.push(key)
      return prev
      // Array.from(contents.keys()).forEach((menuName, idx) => {
      //   addPost[menuName] = this.db[alias] & (1 << idx) ? true : false
      // })

      // this[alias] = Array.from(contents).reduce((initial, [key, value]) => {
      //   if (addPost) return addPost[key] ? [...initial, value] : [...initial]
      // }, [])
    }, [])
  }
  _getParse(alias) {
    const contents = lessonMenu.get(alias).contents
    this[`parse_${alias}`] = this[alias].map(v => contents.get(v))
  }
}

//const keys = ["course", "curriculum", "kind"]

export const alias = {
  crs: "course",
  cur: "curriculum",
  kind: "kind",
}
