import { createAction, handleActions } from "redux-actions"
import produce from "immer"
import api from "apis"
import { MINE } from "utils/data"
import React from "react"
import Story from "components/story/story"
import Menu from "components/class/room/menu.js"
import Lesson from "components/post/lesson.js"
import { getDateYMD, getThumb } from "utils"
import { navigate } from "gatsby"
import * as styles from "pages/visible/media/list.module.css"
import peachIcons from "images/peachIcons.png"
import NewBadge from "components/tools/newBadge"

const STORYRECENT = "myShelfReducer/STORYRECENT"
const STORYLIKE = "myShelfReducer/STORYLIKE"
const STORYEXHLIKE = "myShelfReducer/STORYEXHLIKE"
const CLASSRECENT = "myShelfReducer/CLASSRECENT"
const CLASSLIKE = "myShelfReducer/CLASSLIKE"
const CLASSWRITED = "myShelfReducer/CLASSWRITED"
const CLASSROOMLIKE = "myShelfReducer/CLASSROOMLIKE"
const VISLIKECARD = "myShelfReducer/VISLIKECARD"
const VISLIKEMEDIA = "myShelfReducer/VISLIKEMEDIA"

const getStoryRecent = createAction(STORYRECENT)
const getStoryLike = createAction(STORYLIKE)
const getStoryExh = createAction(STORYEXHLIKE)
const getClassRecent = createAction(CLASSRECENT)
const getClassLike = createAction(CLASSLIKE)
const getClassWrited = createAction(CLASSWRITED)
const getClassRoomLike = createAction(CLASSROOMLIKE)
const getVisLikeCard = createAction(VISLIKECARD)
const getVisLikeMedia = createAction(VISLIKEMEDIA)

//최근 스토리
export const getStoryRecentAsync = () => async dispatch => {
  const list = await api.getRecentStory()
  dispatch(getStoryRecent(list))
}

//찜한 스토리
export const getStoryLikeAsync =
  (page, take, order, table) => async dispatch => {
    const data = await api.getLikeStory(page, take, order, table)
    dispatch(getStoryLike(data))
  }
// 찜한 기획전
export const getExhLikeAsync = (page, take, order, table) => async dispatch => {
  const data = await api.getLikeStory(page, take, order, table)
  dispatch(getStoryExh(data))
}

//최근 본 수업나눔
export const getRecentLessonAsync = () => async dispatch => {
  const data = await api.getRecentLesson()
  console.log(data)
  dispatch(getClassRecent(data))
}

//찜한 수업나눔
export const getLessonLikeAsync =
  (page, take, order, table) => async dispatch => {
    const data = await api.getLikeStory(page, take, order, table)
    dispatch(getClassLike(data))
  }
// 작성한 수업나눔
export const getLessonWritedAsync = (page, take, order) => async dispatch => {
  const data = await api.getWriteLesson(page, take, order)
  dispatch(getClassWrited(data))
}
//내가 찜한 교실이야기
export const getClassLikedAsync =
  (page, take, order, table) => async dispatch => {
    const data = await api.getLikeStory(page, take, order, table)
    dispatch(getClassRoomLike(data))
  }
//내가 찜한 그림단어
export const getCardLikedAsync =
  (page, take, order, table) => async dispatch => {
    const data = await api.getLikeStory(page, take, order, table)
    dispatch(getVisLikeCard(data))
  }
//내가 찜한 영상
export const getMediaLikedAsync =
  (page, take, order, table) => async dispatch => {
    const data = await api.getLikeStory(page, take, order, table)
    dispatch(getVisLikeMedia(data))
  }
const initialState = {
  [MINE.STORY.recent]: {
    list: [],
  },
  [MINE.STORY.like]: {
    list: [],
    total: 0,
  },
  [MINE.STORY.exhLike]: {
    list: [],
    total: 0,
  },
  [MINE.CLASS.recent]: {
    list: [],
  },
  [MINE.CLASS.like]: {
    list: [],
    total: 0,
  },
  [MINE.CLASS.writed]: {
    list: [],
    total: 0,
  },
  [MINE.CLASS.roomLike]: {
    list: [],
    total: 0,
  },
  [MINE.VIS.likeCard]: {
    list: [],
    total: 0,
  },
  [MINE.VIS.likeMedia]: {
    list: [],
    total: 0,
  },
}

const myShelfReducer = handleActions(
  {
    [STORYRECENT]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.STORY.recent].list = payload.map(story => {
          return (
            <div key={story.story_no}>
              <Story
                story_no={story.story_no}
                registerTime={story.publishTime}
                title={story.title}
                introText={story.introText}
                thumb_title_uuid={story.thumb_title_uuid}
                cateMain={story.cateMain}
                cateSub={story.cateSub}
                Like={story.Like}
                free={story.free}
              />
            </div>
          )
        })
      })
    },
    [STORYLIKE]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.STORY.like].list = payload?.pageList.map(story => (
          <Story
            registerTime={story.publishTime}
            key={story.story_no}
            story_no={story.story_no}
            title={story.title}
            introText={story.introText}
            thumb_title_uuid={story.thumb_title_uuid}
            cateMain={story.cateMain}
            cateSub={story.cateSub}
            Like={story.Like}
            free={story.free}
          />
        ))
        draft[MINE.STORY.like].total = payload?.total
      })
    },
    [STORYEXHLIKE]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.STORY.exhLike].list = payload?.pageList.map(story => (
          <Menu
            key={story.news_no}
            img={getThumb(story.thumb_title_uuid)}
            title={story.title}
            hit={story.hit}
            date={getDateYMD(story.registerTime, "YYYY.MM.DD")}
            writer="피치서가"
            onClick={() => {
              navigate(`/notice/detail/?news_no=${story.news_no}`)
            }}
          />
        ))
        draft[MINE.STORY.exhLike].total = payload?.total
      })
    },
    [CLASSRECENT]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.CLASS.recent].list = payload.map(lesson => (
          <Lesson post={lesson} />
        ))
      })
    },
    [CLASSLIKE]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.CLASS.like].list = payload?.pageList.map(lesson => (
          <Lesson post={lesson} />
        ))
        draft[MINE.CLASS.like].total = payload?.total
      })
    },
    [CLASSROOMLIKE]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.CLASS.roomLike].list = payload?.pageList.map(lesson => (
          <Menu
            key={lesson.talk_no}
            img={getThumb(lesson.thumb_title_uuid)}
            title={lesson.title}
            text={lesson.introText}
            like={lesson.like}
            hit={lesson.hit}
            date={getDateYMD(lesson.publishTime, "YYYY.MM.DD")}
            writer={lesson?.EduTalkWriter?.name}
            onClick={() =>
              navigate(`/class/room/detail/?talk_no=${lesson.talk_no}`)
            }
          />
        ))
        draft[MINE.CLASS.roomLike].total = payload?.total
      })
    },
    [CLASSWRITED]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.CLASS.writed].list = payload?.pageList.map(lesson => (
          <Lesson post={lesson} />
        ))
        draft[MINE.CLASS.writed].total = payload?.total
      })
    },
    [VISLIKECARD]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.VIS.likeCard].list = payload?.pageList.map(post => (
          <div
            key={post.illust_no}
            onClick={() => {
              navigate(`/visible/card/detail?illust_no=${post.illust_no}`)
            }}
            style={{
              width: "1060px",
              height: "250px",
              color: "#fff",
              fontFamily: "RIDIBatang",
              marginLeft: "20px",
            }}
          >
            <img
              src={getThumb(post.thumb_title_uuid)}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            />
          </div>
        ))
        draft[MINE.VIS.likeCard].total = payload?.total
      })
    },
    [VISLIKEMEDIA]: (state, { payload }) => {
      return produce(state, draft => {
        draft[MINE.VIS.likeMedia].list = payload?.pageList.map(post => (
          <div
            key={post.video_no}
            onClick={() =>
              navigate(`/visible/media/detail?video_no=${post.video_no}`)
            }
            className={styles.video}
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src={getThumb(post.thumb_title_uuid)}
              className={styles.videoImg}
            />
            <div
              className={styles.text}
              style={{
                gap: "17px",
              }}
            >
              <img
                src={peachIcons}
                style={{
                  width: "40px",
                  height: "40px",
                  marginTop: "5px",
                }}
              />
              <div>
                <div>{post.title}</div>
                <div>{post.introText}</div>
              </div>
            </div>
          </div>
        ))
        draft[MINE.VIS.likeMedia].total = payload?.total
      })
    },
  },
  initialState
)

export default myShelfReducer
